import axios, { type AxiosError } from 'axios';
import { type DateRange } from '@mui/lab/DateRangePicker';
import { getCookie } from '../utils/getCookie';
import { TOKEN_VARIABLE_NAME } from '../consts';
import { BASE_URL } from './consts';

export enum What {
  One = 1,
  Two = 2,
  W = 'W',
}

export type TInjectionDose = {
  dose: number;
  drug: string;
  id: string;
  solvent: string;
  volume: number;
};

export type TInjection = {
  injection: {
    ID: string;
    dt: string;
    what: What;
    course: string;
  };
  injection_dose: TInjectionDose[];
};

export type TInjections = TInjection[];

export const getInjectionsAll = async ([]: number[] | DateRange<number>): Promise<
  TInjections | AxiosError
> =>
  axios
    .get(`${BASE_URL}/injections`, {
      headers: {
        Authorization: `Bearer ${getCookie(TOKEN_VARIABLE_NAME)}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => error);
