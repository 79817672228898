export const getFormattedDate = (date?: string | number | null): string => {
  const currentDate = date ? new Date(date) : new Date();
  return [
    currentDate.getFullYear(),
    (currentDate.getMonth() + 1).toString().padStart(2, '0'),
    currentDate.getDate().toString().padStart(2, '0'),
  ].join('-');
};

export const getFormattedDate2 = (date?: string | number | null): string => {
  const currentDate = date ? new Date(date) : new Date();
  return [
    currentDate.getDate().toString().padStart(2, '0'),
    (currentDate.getMonth() + 1).toString().padStart(2, '0'),
    currentDate.getFullYear(),
  ].join('.');
};

// 2022-03-11 04:15
export const getFormattedDateAndTimeWithoutConverting = (date: string): string => {
  const [part1, part2] = date.split('T');
  return `${part1} ${part2.substring(0, 5)}`;
};

// 2022-03-11
export const getFormattedDateWithoutConverting = (date: string) => date.split('T')[0];

// 04:15
export const getFormattedTimeWithoutConverting = (date: string) =>
  date.split('T')[1].substring(0, 5);
