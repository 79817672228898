import React, { useCallback, useState, useEffect } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import Check from '@mui/icons-material/Check';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Feedback from '../Feedback';
import { createProfile, Sex, type TUserRegister } from '../../api/createProfile';
import {
  getSportTypes,
  type TSportTypes,
  type TChildSportType,
  type TSportType,
} from '../../api/getSportTypes';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import { getFormattedDate } from '../../helpers/getFormattedDate';
import { routes } from '../../routes';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@mui/material';
import AgreementModal from './AgreemetModal';

const styles = {
  allCenter: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
  },
  button: {
    marginTop: '20px',
    display: 'block',
  },
  error: {
    color: 'red',
  },
  formControl: {
    minWidth: '266px',
    marginTop: '10px',
  },
  normalize: {
    margin: '10px 0 0 0',
  },
  mt10: {
    marginTop: '10px',
  },
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type TError = {
  login?: string;
  pass?: string;
  email?: string;
  sex?: string;
  birthday?: string;
  typesports?: string;
  checkbox_agreement?: string;
};

type TOption = {
  value: string;
  id: number;
};

function Index(props) {
  const { t } = useTranslation();
  const [error, setError] = useState<string>();
  const [sportTypes, setSportTypes] = useState<TSportTypes>([]);
  const [selectedSports, setSelectedSports] = useState<TOption[]>([]);
  const [anchorEl, setAnchorEl] = useState({});

  useEffect(() => {
    async function fetchData() {
      const res = await getSportTypes();
      if (!(res instanceof Error)) {
        setSportTypes(res);
      } else {
        setError(getErrorMessage(res));
      }
    }
    fetchData();
  }, []);
  const handleSportMenuClick = (event, name) => {
    setAnchorEl({ [name.id]: event.currentTarget });
  };
  const handleSportMenuClose = () => {
    setAnchorEl({});
  };

  const handleChange = React.useCallback(
    (child: TChildSportType) => {
      const prevSelected: TOption[] = [...selectedSports];
      const index = prevSelected.findIndex((item) => item.id === child.id);
      if (index !== -1) {
        // to remove from array of selected sport types
        prevSelected[index] = {
          id: child.id,
          value: '',
        };
      } else {
        prevSelected.push({ id: child.id, value: child.name });
      }
      setSelectedSports(prevSelected);
    },
    [selectedSports]
  );
  const isSportSelected = React.useCallback(
    (id) => {
      const index = selectedSports.findIndex((item) => item.id === id);
      return index !== -1;
    },
    [selectedSports]
  );

  const onSubmit = useCallback(
    async (userData: TUserRegister) => {
      const { birthday, ...resData } = userData;
      const birthdayDate = getFormattedDate(birthday);
      setError(undefined);
      const res = await createProfile({
        ...resData,
        birthday: `${birthdayDate}T00:00:00Z`,
        typesports: selectedSports.filter((sport) => !!sport.value).map((sport) => sport.id),
      });
      if (!(res instanceof Error)) {
        alert(t('thanks_reg') + ' ' + res.email);
        props.history.push('/login');
      } else {
        setError(getErrorMessage(res));
      }
    },
    [props.history, selectedSports]
  );

  const validate = (values) => {
    const errors: TError = {};

    if (!values.checkbox_agreement) {
      errors.checkbox_agreement = t('required_field');
    }
    if (!values.login) {
      errors.login = t('required_field');
    }
    if (!values.pass) {
      errors.pass = t('required_field');
    }
    if (!values.email) {
      errors.email = t('required_field');
    }

    if (!values.sex) {
      errors.sex = t('required_field');
    }

    if (!values.birthday) {
      errors.birthday = t('required_field');
    }

    if (!values.typesports) {
      // врменно до решения ошибки проверки
      //errors.typesports = t('required_field');
    }

    return errors;
  };
  return (
    <Route path={routes.register}>
      <div style={styles.allCenter}>
        <h1>{t('registration')}</h1>
        {error && <h1 style={styles.error}>{error}</h1>}
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Field name="login">
                {({ input, meta }) => (
                  <div style={styles.mt10}>
                    <TextField
                      variant="standard"
                      label={t('login')}
                      {...input}
                      inputProps={{ minLength: 3, maxLength: 30 }}
                    />
                    {meta.error && meta.touched && <div style={styles.error}>{meta.error}</div>}
                  </div>
                )}
              </Field>
              <Field name="pass">
                {({ input, meta }) => (
                  <div style={styles.mt10}>
                    <TextField
                      autoComplete="new-password"
                      variant="standard"
                      label={t('password')}
                      type="password"
                      {...input}
                      inputProps={{ minLength: 3, maxLength: 30 }}
                    />
                    {meta.error && meta.touched && <div style={styles.error}>{meta.error}</div>}
                  </div>
                )}
              </Field>
              <Field name="email">
                {({ input, meta }) => (
                  <div style={styles.mt10}>
                    <TextField
                      variant="standard"
                      label={t('mail')}
                      {...input}
                      inputProps={{ minLength: 3, maxLength: 30 }}
                    />
                    {meta.error && meta.touched && <div style={styles.error}>{meta.error}</div>}
                  </div>
                )}
              </Field>
              <Field name="sex">
                {({ input, meta }) => (
                  <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: 166 }}
                    style={styles.normalize}
                  >
                    <InputLabel id="demo-simple-select-standard-label">{t('gender')}</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      {...input}
                    >
                      <MenuItem value={Sex.M}>{t('male')}</MenuItem>
                      <MenuItem value={Sex.F}>{t('female')}</MenuItem>
                    </Select>
                    {meta.error && meta.touched && <div style={styles.error}>{meta.error}</div>}
                  </FormControl>
                )}
              </Field>
              <Field name="birthday">
                {({ input, meta }) => (
                  <div style={styles.mt10}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disableFuture
                        label={t('date_birth')}
                        openTo="year"
                        views={['year', 'month', 'day']}
                        {...input}
                        renderInput={(params) => <TextField variant="standard" {...params} />}
                      />
                    </LocalizationProvider>

                    {meta.error && meta.touched && <div style={styles.error}>{meta.error}</div>}
                  </div>
                )}
              </Field>
              <Field name="typesports">
                {({ input, meta }) => (
                  <FormControl style={styles.formControl}>
                    <InputLabel id="demo-mutiple-chip-label">{t('sports')}</InputLabel>
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      {...input}
                      value={selectedSports}
                      input={<Input id="select-multiple-chip" />}
                      renderValue={(selected: TOption[]) => (
                        <div>
                          {selected.map((value) => {
                            if (!value.value) {
                              return null;
                            }
                            return <Chip size="small" key={value.value} label={value.value} />;
                          })}
                        </div>
                      )}
                      MenuProps={MenuProps}
                    >
                      {sportTypes &&
                        sportTypes.map((name: TSportType) => (
                          <div>
                            <Button
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              onClick={(event) => handleSportMenuClick(event, name)}
                              endIcon={<ArrowRight />}
                            >
                              {name.name}
                            </Button>
                            <Menu
                              id="simple-menu"
                              anchorEl={anchorEl[name.id]}
                              keepMounted
                              open={Boolean(anchorEl[name.id])}
                              onClose={handleSportMenuClose}
                            >
                              {name.child.map((child) => (
                                <MenuItem key={child.id} onClick={() => handleChange(child)}>
                                  {isSportSelected(child.id) ? <Check /> : null}
                                  {child.name}
                                </MenuItem>
                              ))}
                            </Menu>
                          </div>
                        ))}
                    </Select>
                    {meta.error && meta.touched && <div style={styles.error}>{meta.error}</div>}
                  </FormControl>
                )}
              </Field>
              <Field name="checkbox_agreement">
                {({ input, meta }) => (
                  <div>
                    <Checkbox {...input} /> {t('agree_terms')} <br /> <AgreementModal />
                    {meta.error && meta.touched && <div style={styles.error}>{meta.error}</div>}
                  </div>
                )}
              </Field>
              <Button
                variant="contained"
                color="primary"
                style={styles.button}
                type="submit"
                disabled={submitting}
              >
                {t('register')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={styles.button}
                onClick={() => props.history.push('/login')}
              >
                {t('back')}
              </Button>
            </form>
          )}
        />
      </div>
      <Feedback />
    </Route>
  );
}

export default withRouter(Index);
