import axios, { type AxiosError } from 'axios';
import { getCookie } from '../utils/getCookie';
import { TOKEN_VARIABLE_NAME } from '../consts';
import { BASE_URL } from './consts';

export type TDrug = {
  drug: string;
  formula: string;
  halflife: number;
  id: string;
  mdrug: string;
  mmass: number;
  mmassm: number;
  name: string;
  out: number;
  outt: number;
  type_: number;
};

export type TDrugs = TDrug[];

export const getDrugs = async (): Promise<TDrugs | AxiosError> =>
  axios
    .get(`${BASE_URL}/drugs`, {
      headers: {
        Authorization: `Bearer ${getCookie(TOKEN_VARIABLE_NAME)}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => error);
