import axios, { type AxiosError } from 'axios';
import { getCookie } from '../utils/getCookie';
import { TOKEN_VARIABLE_NAME } from '../consts';
import { BASE_URL } from './consts';

export type TFeedback = {
  email: string;
  name: string;
  feedback: string;
  dt: string;
  location: string;
};

export type TFeedbackRes = {
  dt: string;
  email: string;
  feedback: string;
  id: string;
  location: string;
  name: string;
  owner: string;
};

export const addFeedback = async (feedback: TFeedback): Promise<TFeedbackRes | AxiosError> =>
  axios
    .post(`${BASE_URL}/feedback`, feedback, {
      headers: {
        Authorization: `Bearer ${getCookie(TOKEN_VARIABLE_NAME)}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => error);
