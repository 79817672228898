import React, { useCallback, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import { getCourse, type TCourse } from '../../api/getCourse';
import { getFormattedDate, getFormattedDate2 } from '../../helpers/getFormattedDate';
import AddCourseModal, { SimpleDialog } from './AddCourseModal';
import { deleteCourse } from '../../api/deleteCourse';
import { routes } from '../../routes';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import { COURSE_TARGET, COURSE_TYPE } from '../Pharmacy/InjectionOptions';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { InjectionGraphModal } from '../Pharmacy/InjectionGraphModal';
import DeleteModal from '../Shared/DeleteModal';

import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles(() => ({
  block: {
    padding: '20px',
  },
  error: {
    color: 'red',
  },
}));

export default function Index() {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<TCourse>();
  const classes = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [isHovered] = useState({});
  const [isDeleteInProgress, setIsDeleteInProgress] = useState({});
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [idDell, setidDell] = useState('');

  const theme = useTheme();
  const mdSize = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();

  const convertToArray = (str: string) => {
    const s = str.replace('{', '').replace('}', '');
    return s.split(',');
  };

  const { isLoading, data } = useQuery('courseData', getCourse, {
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const handleClickOpenModal = (course: TCourse) => () => {
    //alert(course.target)
    setSelectedValue({
      ...course,
      course_start: getFormattedDate(course.course_start),
      course_end: getFormattedDate(course.course_end),
    });
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpened(true);
  };

  const handleDeleteInjection = useCallback(
    (id) => async () => {
      setIsDeleteInProgress({ [id]: true });
      await deleteCourse(id);
      await queryClient.fetchQuery('courseData');
      setIsDeleteModalOpened(false);
    },
    [queryClient]
  );

  const [isGraphModalOpened, setGraphModalOpen] = useState(false);
  const handleClickOpenGraphModal = (ID) => () => {
    if (ID) {
      setSelectedValue(ID);
    }
    setGraphModalOpen(true);
  };
  const handleCloseGraphModal = () => {
    setGraphModalOpen(false);
    setSelectedValue(undefined);
  };

  if (data instanceof Error) {
    if (data.response?.status === 401) {
      history.push(routes.login);
      return null;
    }
    return <h3 className={classes.error}>{getErrorMessage(data)}</h3>;
  }

  const getActionButtons = (id: string) => (
    <>
      <IconButton
        color="primary"
        aria-label="show chart"
        component="span"
        onClick={handleClickOpenGraphModal(id)}
      >
        <ShowChartIcon />
      </IconButton>
      <IconButton
        color="primary"
        aria-label="delete"
        component="span"
        onClick={() => {
          setidDell(id);
          handleOpenDeleteModal();
        }}
      >
        <DeleteIcon />
      </IconButton>
    </>
  );

  return (
    <>
      <div>
        <h3>{t('farm_course')}</h3>
        <AddCourseModal />
        {mdSize ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('period')}</TableCell>
                <TableCell>{t('prep_period')}</TableCell>
                <TableCell>{t('goal')}</TableCell>
                <TableCell>{t('note')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row) => (
                  <TableRow key={row.id} hover>
                    <TableCell onClick={handleClickOpenModal(row)}>
                      {getFormattedDate2(row.course_start)} - {getFormattedDate2(row.course_end)}
                    </TableCell>
                    <TableCell onClick={handleClickOpenModal(row)}>
                      {COURSE_TYPE[row.type]}
                    </TableCell>
                    <TableCell onClick={handleClickOpenModal(row)}>
                      {convertToArray(row.target).map((el) => (
                        <div key={el}>{COURSE_TARGET[el]}</div>
                      ))}
                    </TableCell>
                    <TableCell onClick={handleClickOpenModal(row)}>{row.notes}</TableCell>
                    <TableCell>
                      <div className={isHovered[row.id] || isDeleteInProgress[row.id]}>
                        {getActionButtons(row.id)}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ) : (
          <>
            {data &&
              data.map((row, index) => (
                <Card key={index} sx={{ mt: 2 }}>
                  <CardContent>
                    <div>
                      {`${t('prep')}
                        : 
                        ${getFormattedDate2(row.course_start)}
                         - 
                        ${getFormattedDate2(row.course_end)}`}
                    </div>
                    <div>{`${t('prep_period')}: ${COURSE_TYPE[row.type]}`}</div>
                    <div>{`${t('goal')}: ${convertToArray(row.target).map(
                      (el) => COURSE_TARGET[el] + ' '
                    )}`}</div>
                    <div>{`${t('note')}: ${row.notes}`}</div>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={handleClickOpenModal(row)}>
                      {t('edit')}
                    </Button>
                    {getActionButtons(row.id)}
                  </CardActions>
                </Card>
              ))}
          </>
        )}
        {isLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </div>
      <InjectionGraphModal
        injectionId={selectedValue}
        open={isGraphModalOpened}
        onClose={handleCloseGraphModal}
      />
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleCloseModal} />
      <DeleteModal
        text={t('sure_del_cours_farm')}
        customOpen={isDeleteModalOpened}
        onDeleteButtonPress={handleDeleteInjection(idDell)}
        handleClose={() => setIsDeleteModalOpened(false)}
      />
    </>
  );
}
