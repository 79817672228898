import { type AxiosError } from 'axios';

export const getErrorMessage = (errorRes: AxiosError): string => {
  if (errorRes.response) {
    if (errorRes.response.data.details) {
      const errors: string[] = [];
      errorRes.response.data.details.forEach((detail) => {
        return errors.push(`${detail.field} - ${detail.error}`);
      });
      return errors.join('; ');
    } else {
      return errorRes.response.data.message;
    }
  } else {
    return errorRes.message;
  }
};
