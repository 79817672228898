import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import 'devextreme/dist/css/dx.light.css';

import Auth from './components/Auth';
import './App.css';
import Menu from './components/Menu';
import { routes } from './routes';

import ReactGA from 'react-ga';

const mdTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
const queryClient = new QueryClient();
const TRACKING_ID = 'G-DRK4HVB0EL';
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <Router>
        <QueryClientProvider client={queryClient}>
          <Auth />
          <Route
            exact
            path={[
              routes.root,
              routes.metrics,
              routes.pharmacy,
              routes.course,
              routes.subscription,
              routes.cryptocloudsuccessfulpayment,
              routes.cryptocloudfailedpayment,
              routes.users,
              routes.drugs,
              routes.feedbacks,
              routes.profile,
              routes.manual,
              routes.contacts,
              routes.updatehistory,
            ]}
          >
            <Menu />
          </Route>
        </QueryClientProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
