import axios, { type AxiosError } from 'axios';
import { getCookie } from '../utils/getCookie';
import { TOKEN_VARIABLE_NAME } from '../consts';
import { BASE_URL } from './consts';
import { What, type TInjection } from './getInjections';

export type TInjectionDoseAdd = {
  dose: number;
  drug: string;
  id?: string;
  solvent?: string;
  volume?: number;
};

export type TInjectionAdd = {
  injection: {
    ID?: string;
    dt: string;
    what: What;
    course: string;
  };
  injection_dose: TInjectionDoseAdd[];
};

export const addInjection = async (injection: TInjectionAdd): Promise<TInjection | AxiosError> =>
  axios
    .post(`${BASE_URL}/injection`, injection, {
      headers: {
        Authorization: `Bearer ${getCookie(TOKEN_VARIABLE_NAME)}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => error);
