import axios, { type AxiosError } from 'axios';
import { getCookie } from '../utils/getCookie';
import { TOKEN_VARIABLE_NAME } from '../consts';
import { BASE_URL } from './consts';
import { What } from './getInjections';
import { TInjectionDoseAdd } from './addInjectionUsage';

export type TInjectionAddV2 = {
  injection: {
    date: string[];
    what: What;
    times: number[];
    course: string;
  };
  injection_dose: TInjectionDoseAdd[];
};

export type TRes = string[]; // ids of copies

export const addInjectionV2 = async (injection: TInjectionAddV2): Promise<TRes | AxiosError> =>
  axios
    .post(`${BASE_URL}/injection2`, injection, {
      headers: {
        Authorization: `Bearer ${getCookie(TOKEN_VARIABLE_NAME)}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => error);
