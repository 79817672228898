import React, { useCallback, useState } from 'react';
import { Route, Link, withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { setCookie } from '../../utils/setCookie';
import Feedback from '../Feedback';
import { routes } from '../../routes';
import { login, type TUserLoginData } from '../../api/login';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import { TOKEN_VARIABLE_NAME } from '../../consts';
import { useTranslation } from 'react-i18next';
import picture from '../../assets/index_pic.png';

const styles = {
  allCenter: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    height: '70vh',
  },
  button: {
    marginTop: '20px',
    width: '180px',
  },
  register: {
    marginTop: '20px',
    marginBottom: '10px',
  },
  forgotPass: {
    marginTop: '10px',
  },
  error: {
    color: 'red',
  },
  Picture: {
    display: 'flex',
    height: 250,
    justifyContent: 'center',
    alignItems: 'center',
  },
};

type TProps = {
  history: string[];
};

type TError = {
  username?: string;
  password?: string;
};

function LoginForm(props: TProps) {
  const { t } = useTranslation();
  const [error, setError] = useState<string>();
  const onSubmit = useCallback(
    async (userData: TUserLoginData) => {
      const token = await login(userData);
      if (!(token instanceof Error)) {
        setCookie(TOKEN_VARIABLE_NAME, token);
        props.history.push('/metrics');
      } else {
        setError(getErrorMessage(token));
      }
    },
    [props.history]
  );
  const validate = (values) => {
    const errors: TError = {};
    if (!values.username) {
      errors.username = t('required_field');
    }
    if (!values.password) {
      errors.password = t('required_field');
    }

    return errors;
  };

  return (
    <Route path={routes.login}>
      <div style={styles.allCenter}>
        <div style={styles.Picture}><img src={picture} width="" height="250" alt="" /></div>
        <h1>{t('entrance')}</h1>
        {error && <h1 style={styles.error}>{error}</h1>}
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Field name="username">
                {({ input, meta }) => (
                  <div>
                    <TextField
                      variant="standard"
                      label={t('login')}
                      {...input}
                      inputProps={{ minLength: 1, maxLength: 30 }}
                    />
                    {meta.error && meta.touched && <div style={styles.error}>{meta.error}</div>}
                  </div>
                )}
              </Field>
              <Field name="password">
                {({ input, meta }) => (
                  <div>
                    <TextField
                      autoComplete="new-password"
                      variant="standard"
                      label={t('password')}
                      type="password"
                      inputProps={{ minLength: 1, maxLength: 30 }}
                      {...input}
                    />
                    {meta.error && meta.touched && <div style={styles.error}>{meta.error}</div>}
                  </div>
                )}
              </Field>
              <Button
                variant="contained"
                color="primary"
                style={styles.button}
                type="submit"
                disabled={submitting}
              >
                {t('sign_in')}
              </Button>
            </form>
          )}
        />
        <br />
        <Link to={routes.restorePassword}>
          <span>{t('forgot_your_password')}</span>
        </Link>
        <div style={styles.register}>{t('create_account')}</div>
        <Link to={routes.register}>
          <span>{t('create_an_account')}</span>
        </Link>
        <br />
        <Link to={routes.userManual}>
          <span>{t('user_manual')}</span>
        </Link>
      </div>
      <Feedback />
    </Route>
  );
}

export default withRouter(LoginForm);
