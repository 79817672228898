import axios, { type AxiosError } from 'axios';
import { getCookie } from '../utils/getCookie';
import { Sex } from './createProfile';
import { TOKEN_VARIABLE_NAME } from '../consts';
import { BASE_URL } from './consts';

export type TUser = {
  Admin: boolean;
  Birthday: string;
  Blocked: boolean;
  Blocked_at: string;
  City: string;
  Count_antro: number;
  Count_injection: number;
  Country: string;
  Date_lastlogin: string;
  Date_registered: string;
  Email: string;
  Id: string;
  Login: string;
  Region: string;
  Sex: Sex;
  Type_sports: string;
};
export type TUsers = TUser[];

export const getUsers = async (): Promise<TUsers | AxiosError> =>
  axios
    .get(`${BASE_URL}/users`, {
      headers: {
        Authorization: `Bearer ${getCookie(TOKEN_VARIABLE_NAME)}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => error);
