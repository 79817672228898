//import { useTranslation } from 'react-i18next';
//const { t } = useTranslation();

export const PILL_DRUGS = Object.freeze({
  '00000010-0003-0000-0000-ff00ff00ff00': 'туринабол',
  '00000011-0003-0000-0000-ff00ff00ff00': 'метилтестостерон',
  '00000012-0003-0000-0000-ff00ff00ff00': 'халотестин',
  '00000013-0003-0000-0000-ff00ff00ff00': 'провирон',
  '00000014-0003-0000-0000-ff00ff00ff00': 'станозолол',
  '00000026-0003-0000-0000-ff00ff00ff00': 'метенолона ацетат',
  '00000027-0003-0000-0000-ff00ff00ff00': 'оксиметолон',
  '00000028-0003-0000-0000-ff00ff00ff00': 'оксандролон',
  '00000029-0003-0000-0000-ff00ff00ff00': 'метандростенолон',
});

export const INJECTION_DRUGS = Object.freeze({
  '00000001-0003-0000-0000-ff00ff00ff00': 'тестостерон',
  '00000002-0003-0000-0000-ff00ff00ff00': 'тестостерона ацетат',
  '00000003-0003-0000-0000-ff00ff00ff00': 'тестостерона пропионат',
  '00000004-0003-0000-0000-ff00ff00ff00': 'тестостерона ципионат',
  '00000005-0003-0000-0000-ff00ff00ff00': 'тестостерона деканоат',
  '00000006-0003-0000-0000-ff00ff00ff00': 'тестостерона энантат',
  '00000007-0003-0000-0000-ff00ff00ff00': 'тестостерона изокапроат',
  '00000008-0003-0000-0000-ff00ff00ff00': 'тестостерона фенилпропионат',
  '00000009-0003-0000-0000-ff00ff00ff00': 'тестостерона ундеканоат',
  //'ffbd9b96-4767-452d-9e86-452fcb4b4cf4': 'Небидо',
  '00000015-0003-0000-0000-ff00ff00ff00': 'нандролона деканоат',
  '00000016-0003-0000-0000-ff00ff00ff00': 'нандролона фенилпропионат',
  '00000017-0003-0000-0000-ff00ff00ff00': 'нандролона ацетат',
  '00000018-0003-0000-0000-ff00ff00ff00': 'тренболон',
  '00000019-0003-0000-0000-ff00ff00ff00': 'тренболона ацетат',
  '00000020-0003-0000-0000-ff00ff00ff00': 'тренболона энантат',
  '00000021-0003-0000-0000-ff00ff00ff00': 'тренболона циклогексаметилкарбонат',
  '00000022-0003-0000-0000-ff00ff00ff00': 'болденона ундецикленат',
  '00000023-0003-0000-0000-ff00ff00ff00': 'дростанолона пропионат',
  '00000024-0003-0000-0000-ff00ff00ff00': 'дростанолона энантат',
  '00000025-0003-0000-0000-ff00ff00ff00': 'метенолона энантат',
  '00000026-0003-0000-0000-ff00ff00ff00': 'метенолона ацетат',
  '00000027-0003-0000-0000-ff00ff00ff00': 'оксиметолон',
  '00000029-0003-0000-0000-ff00ff00ff00': 'метандростенолон',
  '00000030-0003-0000-0000-ff00ff00ff00': 'пустышка',
});

export const DRUGS = {
  ...PILL_DRUGS,
  ...INJECTION_DRUGS,
};