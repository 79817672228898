import React, { useCallback, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useQuery, useQueryClient } from 'react-query';
import { type AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import DatePicker, { type Value } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';

import { type TInjection } from '../../api/getInjections';
import { addInjection } from '../../api/addInjectionUsage';
import { addInjectionV2, type TRes } from '../../api/addInjectionUsageV2';
import { deleteInjection } from '../../api/deleteInjectionUsage';
import { INJECTIONS_SELECT_OPTIONS } from './InjectionOptions';
import { getFormattedDate, getFormattedDate2 } from '../../helpers/getFormattedDate';
import { getCurrentTime } from '../../helpers/getCurrentTime';
import DeleteModal from '../Shared/DeleteModal';
import AddDrugComponent from './AddDrugComponent';
import { TIME_MAP } from './TimeMap';
import { routes } from '../../routes';
import { getErrorMessage } from '../../helpers/getErrorMessage';

import { getCourse } from '../../api/getCourse';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';

const useStyles = makeStyles((theme) => {
  return {
    modal: {
      padding: '20px',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '500px',
      },
      [theme.breakpoints.up('md')]: {
        width: '800px',
      },
    },
    formControls: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    formControl: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
      },
    },
    inputsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    error: {
      color: 'red',
      marginLeft: '20px',
    },
    mb40: {
      margin: '20px 0 10px 0',
    },
    mr20: {
      marginRight: '20px',
    },
    inputMinWidth: {
      minWidth: '100px',
      fontSize: '12px !important',
    },
    customInput: {
      cursor: 'text',
      fontSize: '12px',
      fontWeight: 400,
      height: '30px',
      letterSpacing: '0.00938em',
      border: 0,
      borderBottom: '1px solid grey',
    },
    baseFormElement: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '460px',
      },
    },
    baseLargeFormElement: {
      [theme.breakpoints.up('md')]: {
        width: '760px',
      },
    },
  };
});

type TInjectionDoseError = {
  dose?: string;
  drug?: string;
  solvent?: string;
  volume?: string;
};

type TError = {
  dt?: string;
  time?: string;
  what?: string;
  course?: string;
  injection_dose?: TInjectionDoseError[];
};

export function SimpleDialog(props) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { onClose, selectedValue, open } = props;
  const classes = useStyles();
  const [error, setError] = useState<string>();
  const [times, setTimes] = useState([]);
  const [calendarValues, setCalendarValues] = useState<Value>();
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpened(true);
  };
  const handleClose = useCallback(() => {
    onClose(selectedValue);
    setError(undefined);
    setTimes([]);
    setCalendarValues([]);
    setIsDeleteModalOpened(false);
  }, [selectedValue, onClose]);
  const handleDeleteInjectUsage = useCallback(async () => {
    if (selectedValue) {
      await deleteInjection(selectedValue.injection.ID);
      await queryClient.invalidateQueries('injectionsData');
    }
    handleClose();
  }, [selectedValue, handleClose, queryClient]);
  const handleChangeTime = (event) => {
    const {
      target: { value },
    } = event;
    setTimes(value);
  };
  const handleRes = useCallback(async (res: AxiosError | TInjection | TRes) => {
    if (!(res instanceof Error)) {
      await queryClient.invalidateQueries('injectionsData');
      handleClose();
    } else if (res.response && res.response.status === 401) {
      history.push(routes.login);
    } else {
      setError(getErrorMessage(res));
    }
  }, []);
  const theme = useTheme();
  const smSize = useMediaQuery(theme.breakpoints.up('sm'));

  const dataCourse = useQuery('courseData', getCourse, {
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  }).data;

  if (dataCourse instanceof Error) {
    if (dataCourse.response?.status === 401) {
      history.push(routes.login);
      return null;
    }
    return <h3 className={classes.error}>{getErrorMessage(dataCourse)}</h3>;
  }

  const onSubmit = async (data) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { dt, time, injection_dose, ...resData } = data;
    //const timeZoneOffset = new Date().getTimezoneOffset() / 60;
    const [hours, minutes] = time.split(':');
    const mappedInjectionDose = injection_dose
      .filter((inj) => inj !== null)
      .map((inj) => ({
        dose: Number(inj.dose),
        drug: inj.drug,
        solvent: inj.solvent,
        volume: Number(inj.volume),
      }));

    const totalVolume = mappedInjectionDose
      .map((dose) => dose.volume)
      .reduce((acc = 0, volume = 0) => volume + acc, 0);

    if (totalVolume && totalVolume > 10) {
      setError("{t('err_vol_10')}");//вот тут посмотри
      return;
    }

    if (!selectedValue || selectedValue.injection.ID) {
      const res = await addInjection({
        injection: {
          ID: selectedValue ? selectedValue.injection.ID : undefined,
          //dt: `${dt}T${Number(hours) + timeZoneOffset}:${minutes}:00Z`,
          dt: `${dt}T${Number(hours)}:${minutes}:00Z`,
          what: resData.what,
          course: resData.course,
        },
        injection_dose: mappedInjectionDose,
      });
      await handleRes(res);
    } else {
      if (Array.isArray(calendarValues)) {
        if (calendarValues?.length === 0 && times.length === 0) {
          //setError('Выбери хотя бы одну дату и время');
          setError(t('choose_dt'));
          return;
        }

        if (calendarValues?.length === 0) {
          setError(t('choose_d'));
          return;
        }

        if (times.length === 0) {
          setError(t('choose_t'));
          return;
        }
        const res = await addInjectionV2({
          injection: {
            what: resData.what,
            date: calendarValues.map((value) => getFormattedDate(value.toDate())),
            times,
            course: resData.course,
          },
          injection_dose: mappedInjectionDose,
        });
        await handleRes(res);
      } else {
        setError(t('choose_dt'));
      }
    }
  };

  const validate = (values) => {
    const errors: TError = {};
    if (!values.dt) {
      errors.dt = t('required_field');
    }

    if (!values.time) {
      errors.time = t('required_field');
    }

    if (!values.what) {
      errors.what = t('required_field');
    }

    if (!values.course) {
      errors.course = t('required_field');
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const injection_dose: TInjectionDoseError[] = [{}];
    if (values.injection_dose[0]?.dose === undefined) {
      injection_dose[0].dose = t('required_field');
    }

    if (values.injection_dose[0]?.drug === undefined) {
      injection_dose[0].drug = t('required_field');
    }

    if (values.what !== 'W') {
      if (values.injection_dose[0]?.volume === undefined) {
        injection_dose[0].volume = t('required_field');
      }
      if (values.injection_dose[0]?.solvent === undefined) {
        injection_dose[0].solvent = t('required_field');
      }
    }
    errors.injection_dose =
      Object.keys(injection_dose[0]).length === 0 ? undefined : injection_dose;

    return errors;
  };

  return (
    <Dialog
      fullWidth={!smSize}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        {selectedValue && selectedValue.injection.ID ? t('edit1') : t('adding')}
      </DialogTitle>
      {error && <h2 className={classes.error}>{error}</h2>}

      {dataCourse && dataCourse?.length == 0 && (
        <h3 className={classes.error}>
          {t('err_not_add_c')}. <a href="/course">{t('fill_here')}</a>
        </h3>
      )}
      {selectedValue && selectedValue.injection.ID ? (
        <Box
          sx={{
            bgcolor: 'warning.main',
            color: 'warning.contrastText',
            p: 2,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {t('press_save')}
        </Box>
      ) : null}
      <Form
        onSubmit={onSubmit}
        validate={validate}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={
          selectedValue || {
            what: '1',
            dt: getFormattedDate(),
            time: getCurrentTime(),
            injection_dose: [{}],
          }
        }
        render={({
          handleSubmit,
          submitting,
          values,
          form: {
            mutators: { push },
          },
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.modal}>
              <div>
                <div className={classes.inputsWrapper}>
                  {!selectedValue || selectedValue.injection.ID ? (
                    <Field name="dt">
                      {({ input, meta }) => (
                        <div className={classes.mr20}>
                          <TextField
                            id="date"
                            variant="standard"
                            label={t('date')}
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            {...input}
                          />
                          {meta.error && meta.touched && (
                            <div className={classes.error}>{meta.error}</div>
                          )}
                        </div>
                      )}
                    </Field>
                  ) : (
                    <div className={classes.mr20}>
                      <InputLabel id="demo-multiple-name-label" className={classes.inputMinWidth}>
                        {t('dates')}
                      </InputLabel>
                      <DatePicker
                        multiple
                        value={calendarValues}
                        onChange={setCalendarValues}
                        plugins={[<DatePanel />]}
                        inputClass={classes.customInput}
                      />
                    </div>
                  )}
                  {!selectedValue || selectedValue.injection.ID ? (
                    <Field name="time">
                      {({ input, meta }) => (
                        <div className={classes.mr20}>
                          <InputMask mask="99:99" {...input}>
                            {() => (
                              <TextField
                                variant="standard"
                                label={t('time')}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          </InputMask>
                          {meta.error && meta.touched && (
                            <div className={classes.error}>{meta.error}</div>
                          )}
                        </div>
                      )}
                    </Field>
                  ) : (
                    <div className={classes.mr20}>
                      <InputLabel id="demo-multiple-name-label" className={classes.inputMinWidth}>
                        {t('time')}
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={times}
                        onChange={handleChangeTime}
                        input={<Input className={classes.inputMinWidth} />}
                      >
                        {TIME_MAP.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  )}
                  <Field name="what">
                    {({ input, meta }) => (
                      <div>
                        <TextField
                          id="standard-select-currency"
                          select
                          multiple
                          label={t('adm_option')}
                          variant="standard"
                          className={classes.baseFormElement}
                          {...input}
                          disabled={selectedValue}
                        >
                          {INJECTIONS_SELECT_OPTIONS.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        {meta.error && meta.touched && (
                          <div className={classes.error}>{meta.error}</div>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className={classes.inputsWrapper}>
                  <Field name="course">
                    {({ input, meta }) => (
                      <div>
                        <TextField
                          id="standard-select-currency"
                          select
                          multiple
                          label={t('course')}
                          variant="standard"
                          className={cn(classes.baseFormElement, classes.baseLargeFormElement)}
                          {...input}
                        >
                          {dataCourse &&
                            dataCourse.map((row) => (
                              <MenuItem key={row.id} value={row.id}>
                                {getFormattedDate2(row.course_start)} -{' '}
                                {getFormattedDate2(row.course_end)} {row.notes}
                              </MenuItem>
                            ))}
                        </TextField>
                        {meta.error && meta.touched && (
                          <div className={classes.error}>{meta.error}</div>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <FieldArray name="injection_dose">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <AddDrugComponent
                        key={name}
                        name={name}
                        what={values.what}
                        handleDeleteInjectDose={() => fields.remove(index)}
                      />
                    ))
                  }
                </FieldArray>
                <Button
                  className={classes.mb40}
                  variant="outlined"
                  color="primary"
                  onClick={() => push('injection_dose', undefined)}
                >
                  {t('add_medication')}
                </Button>
              </div>
              <div className={classes.formControls}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                  className={classes.formControl}
                >
                  {t('save')}
                </Button>
                <Button variant="contained" onClick={handleClose} className={classes.formControl}>
                  {t('cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleOpenDeleteModal}
                  disabled={!selectedValue}
                  className={classes.formControl}
                >
                  {t('delete')}
                </Button>
              </div>
            </div>
          </form>
        )}
      />
      <DeleteModal
        text={t('sure_del_use')}
        customOpen={isDeleteModalOpened}
        onDeleteButtonPress={handleDeleteInjectUsage}
        handleClose={() => setIsDeleteModalOpened(false)}
      />
    </Dialog>
  );
}

export default function AddPharmacyModal() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const { t } = useTranslation();
  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        {t('add_use')}
      </Button>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
    </div>
  );
}
