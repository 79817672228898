import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { getUsers, type TUser } from '../../api/getUsers';
import { getFormattedDate } from '../../helpers/getFormattedDate';
import { routes } from '../../routes';

const useStyles = makeStyles(() => ({
  block: {
    padding: '20px',
  },
  error: {
    color: 'red',
  },
}));

export default function Index() {
  const classes = useStyles();
  const history = useHistory();

  const { isLoading, data } = useQuery('usersData', getUsers, {
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  if (data instanceof Error) {
    if (data.response?.status === 401) {
      history.push(routes.login);
      return null;
    }
    return (
      <>
        <div className={classes.block}>
          <h3 className={classes.error}>
            {data.response ? data.response.data.message : data.message}
          </h3>
        </div>
      </>
    );
  }

  return (
    <div className={classes.block}>
      <h3>Пользователи</h3>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Login</TableCell>
            <TableCell>Admin</TableCell>
            <TableCell>Blocked</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Date_registered</TableCell>
            <TableCell>Date_lastlogin</TableCell>
            <TableCell>Sex</TableCell>
            <TableCell>Birthday</TableCell>
            <TableCell>Type_sports</TableCell>
            <TableCell>Country</TableCell>
            <TableCell>Region</TableCell>
            <TableCell>Count_antro</TableCell>
            <TableCell>Count_injection</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((row: TUser) => (
              <TableRow key={row.Id}>
                <TableCell>{row.Login}</TableCell>
                <TableCell>{row.Admin?.toString()}</TableCell>
                <TableCell>{row.Blocked?.toString()}</TableCell>
                <TableCell>{row.Email}</TableCell>
                <TableCell>{getFormattedDate(row.Date_registered)}</TableCell>
                <TableCell>{getFormattedDate(row.Date_lastlogin)}</TableCell>
                <TableCell>{row.Sex}</TableCell>
                <TableCell>{getFormattedDate(row.Birthday)}</TableCell>
                <TableCell>{row.Type_sports}</TableCell>
                <TableCell>{row.Country}</TableCell>
                <TableCell>{row.Region}</TableCell>
                <TableCell>{row.Count_antro}</TableCell>
                <TableCell>{row.Count_injection}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
