import axios, { type AxiosError } from 'axios';
import { getCookie } from '../utils/getCookie';
import { TOKEN_VARIABLE_NAME } from '../consts';
import { BASE_URL } from './consts';

export type TAntro = {
  ID: string;
  basic: boolean;
  dt: string;
  fold_anterrior_iliac: number;
  fold_back: number;
  fold_belly: number;
  fold_chest: number;
  fold_forearm: number;
  fold_hip_front: number;
  fold_hip_inside: number;
  fold_hip_rear: number;
  fold_hip_side: number;
  fold_scapula: number;
  fold_shin: number;
  fold_shoulder_front: number;
  fold_shoulder_rear: number;
  fold_waist_side: number;
  fold_wrist: number;
  fold_xiphoid: number;
  general_age: number;
  general_handlen: number;
  general_height: number;
  general_hip: number;
  general_leglen: number;
  general_shoulders: number;
  general_weight: number;
  notes: string;
  owner: string;
  result_energy: number;
  result_fat: number;
  result_nofat: number;
};

type TAntros = TAntro[];

export const getAntros = async (): Promise<TAntros | AxiosError> =>
  axios
    .get(`${BASE_URL}/antros`, {
      headers: {
        Authorization: `Bearer ${getCookie(TOKEN_VARIABLE_NAME)}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => error);
