import axios, { AxiosError } from 'axios';
import { BASE_URL } from './consts';
import { getCookie } from '../utils/getCookie';
import { TOKEN_VARIABLE_NAME } from '../consts';

export type TCourse = {
  id: string;
  course_start: string;
  course_end: string;
  type: string;
  target: string;
  notes: string;
};

type TCourses = TCourse[];

export const getCourse = async (): Promise<TCourses | AxiosError> =>
  axios
    .get(`${BASE_URL}/course`, {
      headers: {
        Authorization: `Bearer ${getCookie(TOKEN_VARIABLE_NAME)}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => error);
