import React, { useCallback, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';

import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { getAntros, type TAntro } from '../../api/getAntros';
import { getFormattedDate, getFormattedDate2 } from '../../helpers/getFormattedDate';
import AddMetricsModal, { SimpleDialog } from './AddMetricsModal';
import { deleteAntros } from '../../api/deleteAntros';
import { routes } from '../../routes';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import { useTranslation } from 'react-i18next';
import DeleteModal from '../Shared/DeleteModal';

import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles(() => ({
  block: {
    padding: '20px',
  },
  error: {
    color: 'red',
  },
}));

const roundTo2Decimal = (num) => Math.round(num * 100) / 100;



export default function Index() {
  const [open, setOpen] = useState(false);
  const [expandedId, setExpandedId] = React.useState(-1);
  const [selectedValue, setSelectedValue] = useState<TAntro>();
  const classes = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [isHovered] = useState({});
  const [isDeleteInProgress, setIsDeleteInProgress] = useState({});
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [idDell, setidDell] = useState('');

  const theme = useTheme();
  const mdSize = useMediaQuery(theme.breakpoints.up('md'));

  const { t } = useTranslation();
  const getResultRow = (row: TAntro) => {
    //import { useTranslation } from 'react-i18next';

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { result_fat, result_nofat, result_energy } = row;
    return `%${t('gmt')} ${roundTo2Decimal(result_fat)}%, ${t('bgmt')} ${roundTo2Decimal(
        result_nofat
    )} ${t('kg')}, ${t('main_ex')} ${Math.round(result_energy)} ${t('kkal')}`;
  };

  const { isLoading, data } = useQuery('antrosData', getAntros, {
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const handleClickOpenModal = (antro: TAntro) => () => {
    setSelectedValue({
      ...antro,
      dt: getFormattedDate(antro.dt),
    });
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpened(true);
  };

  const handleDeleteInjection = useCallback(
    (id) => async () => {
      setIsDeleteInProgress({ [id]: true });
      await deleteAntros(id);
      await queryClient.fetchQuery('antrosData');
      setIsDeleteModalOpened(false);
    },
    [queryClient]
  );

  const handleExpandClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  if (data instanceof Error) {
    if (data.response?.status === 401) {
      history.push(routes.login);
      return null;
    }
    return <h3 className={classes.error}>{getErrorMessage(data)}</h3>;
  }

  return (
    <>
      <div>
        <h3>{t('anthropometry')}</h3>
        <AddMetricsModal />
        {mdSize ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('date')}</TableCell>
                <TableCell>{t('height')}</TableCell>
                <TableCell>{t('weight')}</TableCell>
                <TableCell>{t('folds')}</TableCell>
                <TableCell align="right">{t('result')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row) => (
                  <TableRow key={row.ID} hover>
                    <TableCell onClick={handleClickOpenModal(row)}>
                      {getFormattedDate2(row.dt)}
                    </TableCell>
                    <TableCell onClick={handleClickOpenModal(row)}>{row.general_height}</TableCell>
                    <TableCell onClick={handleClickOpenModal(row)}>{row.general_weight}</TableCell>
                    <TableCell onClick={handleClickOpenModal(row)}>
                      {row.fold_chest}/{row.fold_belly}/{row.fold_hip_front}
                    </TableCell>
                    <TableCell onClick={handleClickOpenModal(row)} align="right">
                      {getResultRow(row)}
                    </TableCell>
                    <TableCell>
                      <div className={isHovered[row.ID] || isDeleteInProgress[row.ID]}>
                        <IconButton
                          color="primary"
                          aria-label="delete"
                          component="span"
                          onClick={() => {
                            setidDell(row.ID);
                            handleOpenDeleteModal();
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ) : (
          <>
            {data &&
              data.map((row, index) => (
                <Card key={index} sx={{ mt: 2 }}>
                  <CardContent>
                    <div>{`${t('date')}: ${getFormattedDate2(row.dt)}, ${t('height')}: ${row.general_height}`}</div>
                    <div>{`${t('weight')}: ${row.general_weight}, ${t('folds')}: ${row.fold_chest}/${row.fold_belly}/${row.fold_hip_front}`}</div>
                  </CardContent>
                  <Collapse in={expandedId === index} timeout="auto" unmountOnExit>
                    <CardContent>
                      <div>{`${t('result')}: ${getResultRow(row)}`}</div>
                    </CardContent>
                  </Collapse>
                  <CardActions>
                    <Button
                      size="small"
                      onClick={() => handleExpandClick(index)}
                      aria-expanded={expandedId === index}
                    >
                      {t('more')}
                    </Button>
                    <Button size="small" onClick={handleClickOpenModal(row)}>
                      {t('edit')}
                    </Button>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      color="primary"
                      component="span"
                      onClick={() => {
                        setidDell(row.ID);
                        handleOpenDeleteModal();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </CardActions>
                </Card>
              ))}
          </>
        )}
        {isLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </div>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleCloseModal} />
      <DeleteModal
        text="{t('sure_del_ant')}"
        customOpen={isDeleteModalOpened}
        onDeleteButtonPress={handleDeleteInjection(idDell)}
        handleClose={() => setIsDeleteModalOpened(false)}
      />
    </>
  );
}
