import React, { useCallback, useState } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Feedback from '../Feedback';
import { restorePassword } from '../../api/restorePassword';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import { routes } from '../../routes';
import { useTranslation } from 'react-i18next';

const styles = {
  allCenter: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
  },
  button: {
    marginTop: '20px',
  },
  error: {
    color: 'red',
  },
};

type TError = {
  email?: string;
};

type TFormValues = {
  email: string;
};

type TProps = {
  history: string[];
};

function Index(props: TProps) {
  const { t } = useTranslation();
  const [error, setError] = useState<string>();
  const onSubmit = useCallback(
    async (values: TFormValues) => {
      const { email } = values;
      const res = await restorePassword(email);
      if (!(res instanceof Error)) {
        props.history.push('/login');
      } else {
        setError(getErrorMessage(res));
      }
    },
    [props.history]
  );

  const validate = (values) => {
    const errors: TError = {};

    if (!values.email) {
      errors.email = t('required_field');
    }

    return errors;
  };
  return (
    <Route path={routes.restorePassword}>
      <div style={styles.allCenter}>
        <h1>{t('password_recovery')}</h1>
        {error && <h1 style={styles.error}>{error}</h1>}
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Field name="email">
                {({ input, meta }) => (
                  <div>
                    <TextField variant="standard" label={t('mail')} {...input} />
                    {meta.error && meta.touched && <div style={styles.error}>{meta.error}</div>}
                  </div>
                )}
              </Field>
              <Button
                variant="contained"
                color="primary"
                style={styles.button}
                type="submit"
                disabled={submitting}
              >
                {t('restore_password')}
              </Button>
              <br />
              <Button
                variant="contained"
                color="primary"
                style={styles.button}
                onClick={() => props.history.push('/login')}
              >
                {t('back')}
              </Button>
            </form>
          )}
        />
      </div>
      <Feedback />
    </Route>
  );
}

export default withRouter(Index);
