import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { useQuery, useQueryClient } from 'react-query';

import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Check from '@mui/icons-material/Check';
import ArrowRight from '@mui/icons-material/ArrowRight';

import { getSportTypes, type TChildSportType } from '../../api/getSportTypes';
import { getProfileData } from '../../api/getProfile';
import { updateProfile, type TUpdateUser } from '../../api/updateProfile';
import { getFormattedDate } from '../../helpers/getFormattedDate';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  header: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  container: {
    padding: '0 20px',
  },
  row: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  space: {
    padding: '0 20px',
  },
  button: {
    marginTop: '20px',
  },
  formControl: {
    minWidth: '120px',
    maxWidth: '600px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  menu: {
    position: 'relative',
  },
  fullWidth: {
    width: '100%',
  },
  error: {
    color: 'red',
    paddingLeft: '20px',
  },
  success: {
    color: 'green',
    paddingLeft: '20px',
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type TOption = {
  id: number;
  value: string;
};

export default function Profile() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [selectedSports, setSelectedSports] = React.useState<TOption[]>();
  const [anchorEl, setAnchorEl] = React.useState({});
  const [error, setError] = useState<string>();
  const [isDataSaved, setIsDataSaved] = useState<boolean>();

  const { isLoading, data } = useQuery(['sportTypes'], () => getSportTypes(), {
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const { isLoading: profileLoading, data: profileData } = useQuery(
    ['profile'],
    () => getProfileData(),
    {
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const isDataNotReady =
    isLoading ||
    profileLoading ||
    profileData instanceof Error ||
    data instanceof Error ||
    !profileData ||
    !data;

  const getInitialValues = React.useCallback(() => {
    if (!isDataNotReady) {
      const { typesports, sex, login, birthday, email } = profileData;
      const allSports: TChildSportType[] = [];
      data.forEach((group) => {
        allSports.push(...group.child);
      });
      const defaultSports: TOption[] = typesports
        .map(
          (id: number) =>
            allSports.find((sport: TChildSportType) => sport.id === id) || {
              id,
              name: '',
            }
        )
        .map((sport: TChildSportType) => ({ id: sport.id, value: sport.name }));
      return {
        defaultSports,
        sex,
        login,
        email,
        birthday: getFormattedDate(birthday),
      };
    }
  }, [profileData, data]);

  React.useEffect(() => {
    if (!isDataNotReady) {
      const initialValues = getInitialValues();
      if (initialValues) {
        setSelectedSports(initialValues.defaultSports);
      }
    }
  }, [data, profileData, getInitialValues]);

  const handleClick = (event, name) => {
    setAnchorEl({ [name.id]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl({});
  };

  const handleChange = React.useCallback(
    (child) => {
      if (child) {
        const prevSelected = selectedSports ? [...selectedSports] : [];
        const index = prevSelected.findIndex((item) => item.id === child.id);
        if (index !== -1) {
          prevSelected[index] = {
            id: child.id,
            value: '',
          };
        } else {
          prevSelected.push({ id: child.id, value: child.name });
        }
        setSelectedSports(prevSelected);
      }
    },
    [selectedSports]
  );
  const isSportSelected = React.useCallback(
    (id) => {
      const index = selectedSports ? selectedSports.findIndex((item) => item.id === id) : -1;
      return index !== -1;
    },
    [selectedSports]
  );

  const handleSubmitForm = async (values: Omit<TUpdateUser, 'ID'>) => {
    setIsDataSaved(false);
    setError(undefined);
    if (!isDataNotReady) {
      const res = await updateProfile({
        ID: profileData.ID,
        ...values,
        birthday: `${values.birthday}T00:00:00Z`,
        typesports: selectedSports
          ? selectedSports.filter((sport) => !!sport.value).map((sport) => sport.id)
          : [],
      });

      if (!(res instanceof Error)) {
        await queryClient.fetchQuery(['profile']);
        setIsDataSaved(true);
      } else if (res.response && res.response.status === 401) {
        history.push('/login');
        return;
      } else {
        setError(getErrorMessage(res));
      }
    }
  };

  if (isDataNotReady) {
    return null;
  }
  return (
    <>
      <div className={classes.header}>
        <h3>{t('data_filled_regist')}</h3>
      </div>
      <Form
        onSubmit={handleSubmitForm}
        initialValues={getInitialValues()}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className={classes.container}>
            <div className={classes.row}>
              <Field name="login">
                {({ input }) => (
                  <div>
                    <TextField
                      variant="standard"
                      label={t('login')}
                      {...input}
                      inputProps={{ minLength: 3, maxLength: 30 }}
                    />
                  </div>
                )}
              </Field>
              <span className={classes.space} />
              <Field name="email">
                {({ input }) => (
                  <div>
                    <TextField
                      variant="standard"
                      label={t('mail')}
                      {...input}
                      inputProps={{ minLength: 3, maxLength: 30 }}
                    />
                  </div>
                )}
              </Field>
            </div>
            <h4>{t('personal_data')}</h4>
            <div className={classes.row}>
              <Field name="sex">
                {({ input }) => (
                  <FormControl variant="standard" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-standard-label">{t('gender')}</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      {...input}
                    >
                      <MenuItem value="M">{t('male')}</MenuItem>
                      <MenuItem value="F">{t('female')}</MenuItem>
                    </Select>
                    {/* {meta.error && meta.touched && <div style={styles.error}>{meta.error}</div>} */}
                  </FormControl>
                )}
              </Field>
              <span className={classes.space} />
              <Field name="birthday">
                {({ input }) => (
                  <>
                    <TextField
                      id="date"
                      variant="standard"
                      label={t('birthday')}
                      type="date"
                      defaultValue="2017-05-24"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...input}
                    />
                    {/* {meta.error && meta.touched && <div style={styles.error}>{meta.error}</div>} */}
                  </>
                )}
              </Field>
            </div>
            <br />
            <div className={classes.row}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-chip-label">
                  {t('sports')} {t('sports_many')}
                </InputLabel>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  value={selectedSports || []}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected: TOption[]) => (
                    <div className={classes.chips}>
                      {selected.map((value) => {
                        if (!value.value) {
                          return null;
                        }
                        return (
                          <Chip
                            size="small"
                            key={value.value}
                            label={value.value}
                            className={classes.chip}
                          />
                        );
                      })}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {!isLoading &&
                    data.map((name) => (
                      <div>
                        <Button
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(event) => handleClick(event, name)}
                          endIcon={<ArrowRight />}
                          className={classes.fullWidth}
                        >
                          {name.name}
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl[name.id]}
                          keepMounted
                          open={Boolean(anchorEl[name.id])}
                          onClose={handleClose}
                        >
                          {name.child.map((child) => (
                            <MenuItem key={child.id} onClick={() => handleChange(child)}>
                              {isSportSelected(child.id) ? <Check /> : null}
                              {child.name}
                            </MenuItem>
                          ))}
                        </Menu>
                      </div>
                    ))}
                </Select>
              </FormControl>
            </div>
            {error && <h2 className={classes.error}>{error}</h2>}
            {isDataSaved && <h2 className={classes.success}>{t('data_saved_succ')}</h2>}
            <div className={classes.button}>
              <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                {t('send')}
              </Button>
            </div>
          </form>
        )}
      />
    </>
  );
}
