// возвращает куки с указанным name,
// или undefined, если ничего не найдено
export function getCookie(name: string): string | undefined {
  const matches = document.cookie.match(
    new RegExp(
      // eslint-disable-next-line
      '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}
