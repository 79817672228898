import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import ru from './translations/ru.json';
import en from './translations/en.json';

const resources = {
  ru,
  en,
};

export const availableLanguages = Object.keys(resources);

i18n.use(initReactI18next).use(Backend).use(LanguageDetector).init({
  resources,
  defaultNS: 'common',
  fallbackLng: 'ru',
});
