import axios, { type AxiosError } from 'axios';
import { getCookie } from '../utils/getCookie';
import { TOKEN_VARIABLE_NAME } from '../consts';
import { BASE_URL } from './consts';
import { Sex } from './createProfile';

export type TUpdateUser = {
  ID: string;
  birthday: string;
  email: string;
  login: string;
  sex: Sex;
  typesports: number[];
};

type TUpdateUserRes = {
  ID: string;
  Passwd: string;
  admin: boolean;
  birthday: string;
  date_lastlogin: string;
  date_registered: string;
  email: string;
  login: string;
  sex: Sex;
  typesports: number[];
};

export const updateProfile = async (
  updateUserData: TUpdateUser
): Promise<TUpdateUserRes | AxiosError> =>
  axios
    .post(`${BASE_URL}/updateprofile`, updateUserData, {
      headers: {
        Authorization: `Bearer ${getCookie(TOKEN_VARIABLE_NAME)}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => error);
