import React from 'react';

import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  block: {
    padding: '20px',
  },
  error: {
    color: 'red',
  },
}));

export default function Index() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.block}>
      <h3>{t('succ_pay')}</h3>
      <h3>{t('reenter')}</h3>
    </div>
  );
}
