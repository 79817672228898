import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Table, TableBody } from '@material-ui/core';
import {useTranslation} from "react-i18next";

export default function Contacts() {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <h1>{t('contacts')}</h1>

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('con_fullname')}</TableCell>
              <TableCell>{t('con_llcmorch')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{t('con_factadress')}</TableCell>
              <TableCell>{t('con_factadrtxt')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('con_mail')}</TableCell>
              <TableCell>info@chartdrug.com</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h1>{t('con_rekviz')}</h1>

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('con_fullname')}</TableCell>
              <TableCell>{t('con_llcmorch')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{t('con_inn')}</TableCell>
              <TableCell>7743345978</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('con_kpp')}</TableCell>
              <TableCell>774301001</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('con_ogrn')}</TableCell>
              <TableCell>1207700311818</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('con_uradress')}</TableCell>
              <TableCell>{t('con_uradresstxt')}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
}
