import React, { useCallback, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import FileCopy from '@mui/icons-material/FileCopy';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import {
  getInjectionsAll,
  type TInjection,
  What,
  TInjectionDose,
} from '../../api/getInjectionsAll';
import { deleteInjection } from '../../api/deleteInjectionUsage';
import { deleteMultipleInjections } from '../../api/deleteMultipleInjections';
import {
  getFormattedDate,
  getFormattedDateAndTimeWithoutConverting,
  getFormattedTimeWithoutConverting,
  getFormattedDateWithoutConverting,
} from '../../helpers/getFormattedDate';
import { getCurrentTime } from '../../helpers/getCurrentTime';
import { DRUGS } from './DrugsMap';
import { InjectionGraphModal } from './InjectionGraphModal';
import AddPharmacyModal, { SimpleDialog } from './AddPharmacyModal';
import { INJECTIONS_OPTIONS } from './InjectionOptions';
import { routes } from '../../routes';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import DeleteModal from '../Shared/DeleteModal';

import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => {
  return {
    block: {
      padding: '20px',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    mb20: {
      marginBottom: '20px',
    },
    mbt20: {
      marginTop: '20px',
      marginBottom: '20px',
    },
    between: {
      justifyContent: 'space-between',
    },
    hidden: {
      visibility: 'hidden',
    },
    pl13: {
      paddingLeft: '13px',
    },
    error: {
      color: 'red',
      marginLeft: '20px',
    },
    deleteButton: {
      marginTop: '10px',
      marginLeft: 0,
      [theme.breakpoints.up('sm')]: {
        marginLeft: '20px',
        marginTop: 0,
      },
    },
    deleteButtonWrapper: {
      display: 'block',
      [theme.breakpoints.up('sm')]: {
        display: 'initial',
      },
    },
  };
});

export default function Index() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [isGraphModalOpened, setGraphModalOpen] = useState(false);
  const [isEditPharmacyModalOpened, setEditPharmacyModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  const theme = useTheme();
  const mdSize = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();

  const [selectedInjection, setSelectedInjection] = useState<
    TInjection & {
      what: What;
      dt: string;
      time: string;
      course: string;
    }
  >();
  const [checkedPharms, setCheckedPharms] = useState({});
  const [isMultipleDeletingInProgress, setIsMultipleDeletingInProgress] = useState(false);
  const classes = useStyles();

  const { isLoading, data } = useQuery(['injectionsData'], () => getInjectionsAll([]), {
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [isDeleteModalOpened2, setIsDeleteModalOpened2] = useState(false);
  const [idDell, setidDell] = useState('');

  if (data instanceof Error) {
    if (data?.response?.status === 401) {
      history.push(routes.login);
      return null;
    }

    return <h3 className={classes.error}>{getErrorMessage(data)}</h3>;
  }

  const handleClickOpenGraphModal = (ID) => () => {
    if (ID) {
      setSelectedValue(ID);
    }
    setGraphModalOpen(true);
  };
  const handleCloseGraphModal = () => {
    setGraphModalOpen(false);
    setSelectedValue(undefined);
  };
  const handleOnSelect = (event) => {
    const id = event.target.value;
    const { checked } = event.target;
    setCheckedPharms({
      ...checkedPharms,
      [id]: checked,
    });
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleDeleteMultiplePharms = useCallback(async () => {
    await setIsMultipleDeletingInProgress(true);
    const injectionIds = Object.keys(checkedPharms).filter((key) => !!checkedPharms[key]);
    await deleteMultipleInjections(injectionIds);
    await queryClient.fetchQuery(['injectionsData']);
    await setIsMultipleDeletingInProgress(false);
    setIsDeleteModalOpened2(false);
  }, [queryClient, checkedPharms]);

  const handleSelectAll = () => {
    if (data) {
      const injectionIds = data.map(({ injection }) => injection.ID);
      const tmpCheckedPharms = injectionIds.reduce((acc, elem) => {
        acc[elem] = true;
        return acc;
      }, {});
      setCheckedPharms(tmpCheckedPharms);
    }
  };

  const handleUnSelectAll = () => {
    if (data) {
      const injectionIds = data.map(({ injection }) => injection.ID);
      const tmpCheckedPharms = injectionIds.reduce((acc, elem) => {
        acc[elem] = false;
        return acc;
      }, {});
      setCheckedPharms(tmpCheckedPharms);
    }
  };

  const handleClickOpenEditPharmacyModal = (injection, injection_dose) => () => {
    setSelectedInjection({
      injection,
      injection_dose,
      what: injection.what,
      dt: injection.dt ? getFormattedDateWithoutConverting(injection.dt) : getFormattedDate(),
      time: injection.dt ? getFormattedTimeWithoutConverting(injection.dt) : getCurrentTime(),
      course: injection.course,
    });
    setEditPharmacyModalOpen(true);
  };
  const handleCloseEditPharmacyModal = () => {
    setEditPharmacyModalOpen(false);
  };

  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpened(true);
  };

  const handleOpenDeleteModal2 = () => {
    setIsDeleteModalOpened2(true);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleDeleteInjection = useCallback(
    (id) => async () => {
      await deleteInjection(id);
      await queryClient.fetchQuery(['injectionsData']);
      setIsDeleteModalOpened(false);
    },
    [queryClient]
  );

  const getActionButtons = (id: string, what: What, dose: TInjectionDose[]) => (
    <>
      <IconButton
        color="primary"
        aria-label="show chart"
        component="span"
        onClick={handleClickOpenGraphModal(id)}
      >
        <ShowChartIcon />
      </IconButton>
      <IconButton
        color="primary"
        aria-label="copy"
        component="span"
        onClick={handleClickOpenEditPharmacyModal(
          {
            what: what,
          },
          dose
        )}
      >
        <FileCopy />
      </IconButton>
      <IconButton
        color="primary"
        aria-label="delete"
        component="span"
        onClick={() => {
          setidDell(id);
          handleOpenDeleteModal();
        }}
      >
        <DeleteIcon />
      </IconButton>
    </>
  );

  //const { t } = useTranslation();
  return (
    <>
      <div>
        <h3>{t('use_farm')}</h3>
        <div className={[classes.flex, classes.mb20, classes.between].join(' ')}>
          <AddPharmacyModal />
        </div>
        <div className={classes.mbt20}>
          <Button variant="outlined" color="primary" onClick={handleSelectAll}>
            {t('select_all')}
          </Button>
          <span> </span>
          <Button variant="outlined" color="primary" onClick={handleUnSelectAll}>
            {t('select_canc')}
          </Button>
          <span
            className={cn(classes.deleteButtonWrapper, {
              [classes.hidden]: !Object.values(checkedPharms).find((flag) => !!flag),
            })}
          >
            <Button
              variant="outlined"
              color="primary"
              startIcon={<DeleteIcon />}
              onClick={handleOpenDeleteModal2}
              className={classes.deleteButton}
            >
              {t('delete')}
            </Button>
          </span>
        </div>
        {mdSize ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>{t('date')}</TableCell>
                <TableCell>{t('adm_type')}</TableCell>
                <TableCell>{t('medication1')} - {t('dose_vol')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                !isMultipleDeletingInProgress &&
                data.map(({ injection, injection_dose }) => (
                  <TableRow key={injection.ID} hover>
                    <TableCell>
                      <FormControl>
                        <Checkbox
                          onChange={handleOnSelect}
                          value={injection.ID}
                          checked={checkedPharms[injection.ID] || false}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell
                      onClick={handleClickOpenEditPharmacyModal(injection, injection_dose)}
                    >
                      {getFormattedDateAndTimeWithoutConverting(injection.dt)}
                    </TableCell>
                    <TableCell
                      onClick={handleClickOpenEditPharmacyModal(injection, injection_dose)}
                    >
                      {INJECTIONS_OPTIONS[injection.what]}
                    </TableCell>
                    <TableCell
                      onClick={handleClickOpenEditPharmacyModal(injection, injection_dose)}
                    >
                      {injection_dose.map((dose) => (
                        <div key={dose.id}>
                          {DRUGS[dose.drug]} - {dose.dose} / {dose.volume}
                        </div>
                      ))}
                    </TableCell>
                    <TableCell>
                      <div>{getActionButtons(injection.ID, injection.what, injection_dose)}</div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ) : (
          <>
            {data &&
              !isMultipleDeletingInProgress &&
              data.map(({ injection, injection_dose }, index) => (
                <Card key={index} sx={{ mt: 2 }}>
                  <CardContent>
                    <div>{`${t('date')}: ${getFormattedDateAndTimeWithoutConverting(injection.dt)}`}</div>
                    <div>{`${t('adm_type')}: ${INJECTIONS_OPTIONS[injection.what]}`}</div>
                    <br />
                    <div>{t('medication1')} - {t('dose_vol')}:</div>
                    {injection_dose.map((dose) => (
                      <div key={dose.id}>
                        {DRUGS[dose.drug]} - {dose.dose} / {dose.volume}
                      </div>
                    ))}
                  </CardContent>
                  <CardActions>
                    <FormControl>
                      <Checkbox
                        onChange={handleOnSelect}
                        value={injection.ID}
                        checked={checkedPharms[injection.ID] || false}
                      />
                    </FormControl>
                    <Button
                      size="small"
                      onClick={handleClickOpenEditPharmacyModal(injection, injection_dose)}
                    >
                      {t('edit')}
                    </Button>
                    {getActionButtons(injection.ID, injection.what, injection_dose)}
                  </CardActions>
                </Card>
              ))}
          </>
        )}

        {(isLoading || isMultipleDeletingInProgress) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </div>
      <InjectionGraphModal
        injectionId={selectedValue}
        open={isGraphModalOpened}
        onClose={handleCloseGraphModal}
      />
      <SimpleDialog
        selectedValue={selectedInjection}
        open={isEditPharmacyModalOpened}
        onClose={handleCloseEditPharmacyModal}
      />
      <DeleteModal
        text={t('sure_del_use')}
        customOpen={isDeleteModalOpened}
        onDeleteButtonPress={handleDeleteInjection(idDell)}
        handleClose={() => setIsDeleteModalOpened(false)}
      />
      <DeleteModal
        text={t('sure_del_list')}
        customOpen={isDeleteModalOpened2}
        onDeleteButtonPress={handleDeleteMultiplePharms}
        handleClose={() => setIsDeleteModalOpened2(false)}
      />
    </>
  );
}
