import axios, { type AxiosError } from 'axios';
import { BASE_URL } from './consts';

export type TChildSportType = {
  id: number;
  name: string;
};

export type TSportType = {
  id: number;
  name: string;
  child: TChildSportType[];
};
export type TSportTypes = TSportType[];

export const getSportTypes = async (): Promise<TSportTypes | AxiosError> =>
  axios
    .get(`${BASE_URL}/spr/sports/ru`)
    .then((response) => response.data)
    .catch((error) => error);
