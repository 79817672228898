import React, { useCallback } from 'react';
import { Link, Route, withRouter } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MuiContainer from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { MainListItems } from './listItems';
import Metrics from '../Metrics';
import Course from '../Course';
import Pharmacy from '../Pharmacy';
import Feedback from '../Feedback';
import Profile from '../Profile';
import Manual from '../Manual';
import Сontacts from '../Сontacts';
import Users from '../Users';
import Subscription from '../Subscription';
import UpdateHistory from '../UpdateHistory';
import CryptocloudSuccessfulPayment from '../CryptocloudSuccessfulPayment';
import CryptocloudFailedPayment from '../CryptocloudFailedPayment';
import Drugs from '../Drugs';
import Feedbacks from '../Feedbacks';

import { TOKEN_VARIABLE_NAME } from '../../consts';
import { deleteCookie } from '../../utils/deleteCookie';
import { parseJwt } from '../../helpers/parseJwt';
import { getCookie } from '../../utils/getCookie';
import { routes } from '../../routes';
import { useTranslation } from 'react-i18next';

const drawerWidth = 300;

type RootProps = { open: boolean };

const Container = styled(MuiContainer)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(12),
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<RootProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'absolute',
      whiteSpace: 'nowrap',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: drawerWidth,
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        [theme.breakpoints.up('md')]: {
          width: theme.spacing(9),
        },
      }),
    },
  })
);

function DashboardContent(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = getCookie(TOKEN_VARIABLE_NAME);
  const isProfileOpened = Boolean(anchorEl);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = useCallback(() => {
    deleteCookie(TOKEN_VARIABLE_NAME);
    props.history.push('/login');
  }, [props.history]);

  if (!token) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: '24px',
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            SPORTS PHARMACOLOGY
          </Typography>
          <IconButton
            color="inherit"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={isProfileOpened}
            onClose={handleClose}
          >
            <Link to="/profile">
              <MenuItem onClick={handleClose}>{t('profile')}</MenuItem>
            </Link>
            <Link to="/manual">
              <MenuItem onClick={handleClose}>{t('user_manual')}</MenuItem>
            </Link>
            <Link to="/contacts">
              <MenuItem onClick={handleClose}>{t('contacts')}</MenuItem>
            </Link>
            <Link to="/subscription">
              <MenuItem onClick={handleClose}>{t('subsc')}</MenuItem>
            </Link>
            <Link to="/updatehistory">
              <MenuItem onClick={handleClose}>{t('upd_hist')}</MenuItem>
            </Link>
            {parseJwt(token)?.admin ? (
              <div>
                <Link to="/users">
                  <MenuItem onClick={handleClose}>{t('users')}</MenuItem>
                </Link>
                <Link to="/drugs">
                  <MenuItem onClick={handleClose}>{t('medication')}</MenuItem>
                </Link>
                <Link to="/feedbacks">
                  <MenuItem onClick={handleClose}>{t('requests')}</MenuItem>
                </Link>
              </div>
            ) : null}
            <MenuItem onClick={handleLogout}>{t('exit')}</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" anchor="left" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <MainListItems
            handleNavClick={() => {
              open && toggleDrawer();
            }}
          />
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: 'white',
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Route exact path={routes.pharmacy}>
            <Pharmacy />
          </Route>
          <Route exact path={routes.profile}>
            <Profile />
          </Route>
          <Route exact path={routes.manual}>
            <Manual />
          </Route>
          <Route exact path={routes.contacts}>
            <Сontacts />
          </Route>
          <Route exact path={routes.subscription}>
            <Subscription />
          </Route>
          <Route exact path={routes.updatehistory}>
            <UpdateHistory />
          </Route>
          <Route exact path={routes.cryptocloudsuccessfulpayment}>
            <CryptocloudSuccessfulPayment />
          </Route>
          <Route exact path={routes.cryptocloudfailedpayment}>
            <CryptocloudFailedPayment />
          </Route>
          <Route exact path={routes.users}>
            <Users />
          </Route>
          <Route exact path={routes.drugs}>
            <Drugs />
          </Route>
          <Route exact path={routes.feedbacks}>
            <Feedbacks />
          </Route>
          <Route exact path={[routes.root, routes.metrics]}>
            <Metrics />
          </Route>
          <Route exact path={[routes.course]}>
            <Course />
          </Route>
          <Feedback />
        </Container>
      </Box>
    </Box>
  );
}

export default withRouter(DashboardContent);
