import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { getFormattedDate2 } from '../../helpers/getFormattedDate';
import { getFeedbacks } from '../../api/getFeedbacks';
import { routes } from '../../routes';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  block: {
    padding: '20px',
  },
  error: {
    color: 'red',
  },
}));

export default function Index() {
  const classes = useStyles();
  const history = useHistory();
  const [expandedId, setExpandedId] = React.useState(-1);

  const { isLoading, data } = useQuery('usersData', getFeedbacks, {
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const handleExpandClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  const { t } = useTranslation();

  if (data instanceof Error) {
    if (data.response?.status === 401) {
      history.push(routes.login);
      return null;
    }
    return (
      <>
        <div className={classes.block}>
          <h3 className={classes.error}>
            {data.response ? data.response.data.message : data.message}
          </h3>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={classes.block}>
        <h3>{t('requests')}</h3>
        {data &&
          data.map((row, index) => (
            <Card key={index} sx={{ mt: 2 }}>
              <CardContent>
                <div>{`${getFormattedDate2(row.dt)} ${row.name} ${row.email}`}</div>
                <div>{`${row.feedback}`}</div>
              </CardContent>
              <Collapse in={expandedId === index} timeout="auto" unmountOnExit>
                <CardContent>
                  <div>{`id: ${row.id}`}</div>
                  <div>{`owner: ${row.owner}`}</div>
                  <div>{`location: ${row.location}`}</div>
                </CardContent>
              </Collapse>
              <CardActions>
                <Button
                  size="small"
                  onClick={() => handleExpandClick(index)}
                  aria-expanded={expandedId === index}
                >
                  {t('more')}
                </Button>
              </CardActions>
            </Card>
          ))}
        {isLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </div>
    </>
  );
}
