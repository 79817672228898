import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type TProps = {
  text: string;
  customOpen: boolean;
  onDeleteButtonPress: () => void;
  handleClose: () => void;
};

export default function AlertDialog({
  text,
  customOpen,
  onDeleteButtonPress,
  handleClose,
}: TProps) {
  return (
    <Dialog
      open={customOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Удаление</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>НЕТ</Button>
        <Button onClick={onDeleteButtonPress} autoFocus>
          ДА
        </Button>
      </DialogActions>
    </Dialog>
  );
}
