import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { getDrugs } from '../../api/getDrugs';
import { routes } from '../../routes';
import { useTranslation } from 'react-i18next';

const styles = {
  block: {
    padding: '20px',
  },
  error: {
    color: 'red',
  },
};

export default function Index() {
  const history = useHistory();
  const { isLoading, data } = useQuery('drugsData', getDrugs, {
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const { t } = useTranslation();
  if (data instanceof Error) {
    if (data.response?.status === 401) {
      history.push(routes.login);
      return null;
    }
    return (
      <>
        <div style={styles.block}>
          <h3 style={styles.error}>{data.response ? data.response.data.message : data.message}</h3>
        </div>
      </>
    );
  }

  return (
    <>
      <div>
        <h3>{t('medication')}</h3>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Drug</TableCell>
              <TableCell>Mdrug</TableCell>
              <TableCell>Type_</TableCell>
              <TableCell>Formula</TableCell>
              <TableCell>Mmass</TableCell>
              <TableCell>Mmassm</TableCell>
              <TableCell>Out</TableCell>
              <TableCell>Outt</TableCell>
              <TableCell>Halflife</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.drug}</TableCell>
                  <TableCell>{row.mdrug}</TableCell>
                  <TableCell>{row.type_}</TableCell>
                  <TableCell>{row.formula}</TableCell>
                  <TableCell>{row.mmass}</TableCell>
                  <TableCell>{row.mmassm}</TableCell>
                  <TableCell>{row.out}</TableCell>
                  <TableCell>{row.outt}</TableCell>
                  <TableCell>{row.halflife}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {isLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </div>
    </>
  );
}
