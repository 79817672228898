import axios, { type AxiosError } from 'axios';
import { getCookie } from '../utils/getCookie';
import { TOKEN_VARIABLE_NAME } from '../consts';
import { BASE_URL } from './consts';
import { type TAntro } from './getAntros';

export type TAddAntros = {
  ID?: string;
  dt?: string;
  fold_belly: number;
  fold_chest: number;
  fold_hip_front: number;
  general_height: number;
  general_weight: number;
};

export const addAntros = async (antro: TAddAntros): Promise<TAntro | AxiosError> =>
  axios
    .post(`${BASE_URL}/antros`, antro, {
      headers: {
        Authorization: `Bearer ${getCookie(TOKEN_VARIABLE_NAME)}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => error);
