import React, { Route, Redirect } from 'react-router-dom';
import LoginForm from './LoginForm';
import RestorePasswordForm from './RestorepasswordForm';
import RegisterForm from './RegisterForm';
import UserManualForm from '../Manual/UserManualForm';
import { getCookie } from '../../utils/getCookie';
import { TOKEN_VARIABLE_NAME } from '../../consts';
import { routes } from '../../routes';

export default function Index() {
  const token = getCookie(TOKEN_VARIABLE_NAME);
  return (
    <Route path={routes.root}>
      {!token && <Redirect to={routes.login} />}
      <Route exact path={routes.login}>
        <LoginForm />
      </Route>
      <Route exact path={routes.restorePassword}>
        <RestorePasswordForm />
      </Route>
      <Route exact path={routes.register}>
        <RegisterForm />
      </Route>
      <Route exact path={routes.userManual}>
        <UserManualForm />
      </Route>
    </Route>
  );
}
