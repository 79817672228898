type TOptions = {
  path?: string;
  expires?: Date | string;
  'max-age': number;
};

export function setCookie(
  name: string,
  value: string,
  defaultOption: TOptions = { 'max-age': 3600 }
) {
  const options: TOptions = {
    path: '/',
    ...defaultOption,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }
  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  Object.keys(options).forEach((optionKey) => {
    updatedCookie += `; ${optionKey}`;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  });

  document.cookie = updatedCookie;
}
