import axios, { type AxiosError } from 'axios';
import { getCookie } from '../utils/getCookie';
import { TOKEN_VARIABLE_NAME } from '../consts';
import { BASE_URL } from './consts';

export const deleteMultipleInjections = async (
  injections: string[]
): Promise<string[] | AxiosError> =>
  axios
    .delete(`${BASE_URL}/injections`, {
      headers: {
        Authorization: `Bearer ${getCookie(TOKEN_VARIABLE_NAME)}`,
      },
      data: injections,
    })
    .then((response) => response.data)
    .catch((error) => error);
