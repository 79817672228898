import axios, { type AxiosError } from 'axios';
import { getCookie } from '../utils/getCookie';
import { TOKEN_VARIABLE_NAME } from '../consts';
import { BASE_URL } from './consts';
import { type TCourse } from './getCourse';

export const deleteCourse = async (id: string): Promise<TCourse | AxiosError> =>
  axios
    .delete(`${BASE_URL}/course/${id}`, {
      headers: {
        Authorization: `Bearer ${getCookie(TOKEN_VARIABLE_NAME)}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => error);
