import axios, { type AxiosError } from 'axios';
import { BASE_URL } from './consts';

export enum Sex {
  M = 'M',
  F = 'F',
}

export type TUserRegister = {
  login: string;
  pass: string;
  email: string;
  sex: Sex;
  birthday: string;
  typesports: number[];
};

export type TUserRegisterRes = {
  Admin: boolean;
  ID: string;
  Passwd: string;
  birthday: string;
  date_lastlogin: string;
  date_registered: string;
  email: string;
  login: string;
  sex: string;
  typesports: string;
};

export const createProfile = async (
  userData: TUserRegister
): Promise<TUserRegisterRes | AxiosError> =>
  axios
    .post(`${BASE_URL}/createprofile`, userData)
    .then((response) => response.data)
    .catch((error) => error);
