import axios, { type AxiosError } from 'axios';
import { getCookie } from '../utils/getCookie';
import { TOKEN_VARIABLE_NAME } from '../consts';
import { BASE_URL } from './consts';
import { type TInjection } from './getInjections';

export const deleteInjection = async (id: string): Promise<TInjection | AxiosError> =>
  axios
    .delete(`${BASE_URL}/injection/${id}`, {
      headers: {
        Authorization: `Bearer ${getCookie(TOKEN_VARIABLE_NAME)}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => error);
