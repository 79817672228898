import { useTranslation } from 'react-i18next';
import antroform from '../../assets/antroform.png';
import antroform2 from '../../assets/antroform2.png';
import antroaddform from '../../assets/antroaddform.png';
import coursefarmform from '../../assets/coursefarmform.png';
import coursefarmaddform from '../../assets/coursefarmaddform.png';
import farmform from '../../assets/farmform.png';
import farmaddform from '../../assets/farmaddform.png';
import farmcopyform from '../../assets/farmcopyform.png';
import analizecourseform from '../../assets/analizecourseform.png';
import graphpharm from '../../assets/graphpharm.png';
import React from 'react';

export default function Manual() {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <h1 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {t('user_manual')}
        </h1>

        <h1>О приложении</h1>
        <p>
          &nbsp;&nbsp;Веб-приложение создано для использования спортсменами-любителями, а также
          профессионалами для облегчения ведения дневника и анализа применения спортивной
          фармакологии.
        </p>

        <h1>Возможности приложения</h1>
        <ul>
          <li>
            анализ антропометрических данных (расчет % жировой массы, безжировой массы тела,
            основного обмена)
          </li>
          <li>
            ведение дневника применения фармакологии и дальнейший анализ:
            <ol>
              <li>расчет концентрации препарата</li>
              <li>расчет дня чистоты</li>
              <li>расчет даты ПКТ</li>
              <li>расчет анаболического индекса курса</li>
              <li>расчет андрогенного индекса курса</li>
            </ol>
          </li>
        </ul>

        <h1>Антропометрия</h1>
        <p>
          &nbsp;&nbsp;Начинать работу с приложением следует с заполнения антропометрических данных:
          рост, вес, толщины кожно-жировых складок.
        </p>
        <p>
          &nbsp;&nbsp;
          <img src={antroform} width="auto" height="auto" alt="antroform" />
        </p>
        <p>
          &nbsp;&nbsp;Настоятельно рекомендуем вводить честные данные, так как от этого зависит
          точность дальнейших расчетов. Как измерять складки можно узнать, нажав на знак вопроса в
          форме добавления антропометрии.
        </p>
        <p>
          &nbsp;&nbsp;
          <img src={antroaddform} width="auto" height="auto" alt="antroaddform" />
        </p>
        <p>&nbsp;&nbsp;После заполнения и сохранения данных антропометрии приложение рассчитает:</p>
        <ul>
          <li>% жировой массы тела</li>
          <li>безжировую массу тела</li>
          <li>величину основного обмена</li>
        </ul>
        <p>
          &nbsp;&nbsp;
          <img src={antroform2} width="auto" height="auto" alt="antroform2" />
        </p>

        <h1>Добавление курса фармакологии</h1>
        <p>Следующим шагом следует добавить курс применения фармакологии.</p>
        <p>
          &nbsp;&nbsp;
          <img src={coursefarmform} width="auto" height="auto" alt="coursefarmform" />
        </p>
        <p>
          &nbsp;&nbsp;Здесь необходимо указать даты начала и окончания курса, выбрать период
          подготовки (подготовительный, соревновательный, переходный), указать цели курса
          (увеличение или уменьшение мышечной массы, увеличение или уменьшение жировой массы, и
          т.д). В примечании можно добавить какие-то мысли или просто дать название курсу.
        </p>
        <p>
          &nbsp;&nbsp;
          <img src={coursefarmaddform} width="auto" height="auto" alt="coursefarmaddform" />
        </p>

        <h1>Применение фармакологии</h1>
        <p>
          &nbsp;&nbsp;После добавления курса фармакологии можно переходить непосредственно к
          наполнению этого курса применениями
        </p>
        <p>
          &nbsp;&nbsp;
          <img src={farmform} width="auto" height="auto" alt="farmform" />
        </p>
        <p>
          &nbsp;&nbsp;Жмем &laquo;Добавить применение&raquo; и заполняем форму. Необходимо ввести
          дату, время, выбрать вариант введения (инъекция внутримышечная, инъекция подкожная,
          таблетка). В следующей строке необходимо выбрать курс, к которому относится это
          применение.
        </p>
        <p>
          &nbsp;&nbsp;Далее необходимо выбрать препарат, дозу (мг), объем инъекции и растворитель
          (масло, олеат, вода). В случае применения таблеток объем и растворитель не указываются.
          Если вы применяете в одной инъекции несколько препаратов, то их можно добавить нажав на
          кнопку &laquo;добавить препарат&raquo;. Далее уже по знакомой схеме.
        </p>
        <p>
          &nbsp;&nbsp;На инъекции установлено ограничение по объему &ndash; он не может быть больше
          10мл (это касается как одного препарата, так и их смеси).
        </p>
        <p>
          &nbsp;&nbsp;
          <img src={farmaddform} width="auto" height="auto" alt="farmaddform" />
        </p>
        <p>
          &nbsp;&nbsp;Если у Вас много одинаковых применений (например, вы решили каждый день колоть
          один и тот же препарат в одной и той же дозе или одни и те же таблетки несколько раз в
          день и каждый день), то для упрощения внесения новых применений фармакологии можно
          воспользоваться функцией копирования. Для этого в строке применения жмем на значок
          копирования и в появившемся окне отмечаем даты, когда будут такие же применения, выбираем
          время и выбираем курс.&nbsp;
        </p>
        <p>
          &nbsp;&nbsp;
          <img src={farmcopyform} width="auto" height="auto" alt="farmcopyform" />
        </p>

        <h1>Анализ курса</h1>
        <p>
          &nbsp;&nbsp;После добавления применений фармакологии можно вернуться в меню курсов и
          оценить Ваши планы. Для этого в строке курса жмем на значок графика.
        </p>
        <p>
          &nbsp;&nbsp;
          <img src={analizecourseform} width="auto" height="auto" alt="analizecourseform" />
        </p>
        <p>
          Откроется график, на котором будут показаны изменения концентраций всех используемых
          препаратов и общая концентрация (влияние на уровень тестостерона).
        </p>
        <p>Так же на графике курса будут:</p>
        <ul>
          <li>
            андрогенный и анаболический индексы курса (расчетные величины, характеризующие курс).
            Позволяют сравнивать курсы между собой, например при переходе на новые еще незнакомые
            спортсмену препараты
          </li>
          <li>
            дата ПКТ. Эта дата показывает, когда после данного курса целесообразно начинать
            посткурсовую терапию.
          </li>
          <li>
            при наведении курсора мыши на график можно увидеть концентрацию в конкретный момент
            времени.
          </li>
        </ul>
      </div>
    </>
  );
}
