export const TIME_MAP = [
  { value: 0, label: '00:00' },
  { value: 900000, label: '00:15' },
  { value: 1800000, label: '00:30' },
  { value: 2700000, label: '00:45' },
  { value: 3600000, label: '01:00' },
  { value: 4500000, label: '01:15' },
  { value: 5400000, label: '01:30' },
  { value: 6300000, label: '01:45' },
  { value: 7200000, label: '02:00' },
  { value: 8100000, label: '02:15' },
  { value: 9000000, label: '02:30' },
  { value: 9900000, label: '02:45' },
  { value: 10800000, label: '03:00' },
  { value: 11700000, label: '03:15' },
  { value: 12600000, label: '03:30' },
  { value: 13500000, label: '03:45' },
  { value: 14400000, label: '04:00' },
  { value: 15300000, label: '04:15' },
  { value: 16200000, label: '04:30' },
  { value: 17100000, label: '04:45' },
  { value: 18000000, label: '05:00' },
  { value: 18900000, label: '05:15' },
  { value: 19800000, label: '05:30' },
  { value: 20700000, label: '05:45' },
  { value: 21600000, label: '06:00' },
  { value: 22500000, label: '06:15' },
  { value: 23400000, label: '06:30' },
  { value: 24300000, label: '06:45' },
  { value: 25200000, label: '07:00' },
  { value: 26100000, label: '07:15' },
  { value: 27000000, label: '07:30' },
  { value: 27900000, label: '07:45' },
  { value: 28800000, label: '08:00' },
  { value: 29700000, label: '08:15' },
  { value: 30600000, label: '08:30' },
  { value: 31500000, label: '08:45' },
  { value: 32400000, label: '09:00' },
  { value: 33300000, label: '09:15' },
  { value: 34200000, label: '09:30' },
  { value: 35100000, label: '09:45' },
  { value: 36000000, label: '10:00' },
  { value: 36900000, label: '10:15' },
  { value: 37800000, label: '10:30' },
  { value: 38700000, label: '10:45' },
  { value: 39600000, label: '11:00' },
  { value: 40500000, label: '11:15' },
  { value: 41400000, label: '11:30' },
  { value: 42300000, label: '11:45' },
  { value: 43200000, label: '12:00' },
  { value: 44100000, label: '12:15' },
  { value: 45000000, label: '12:30' },
  { value: 45900000, label: '12:45' },
  { value: 46800000, label: '13:00' },
  { value: 47700000, label: '13:15' },
  { value: 48600000, label: '13:30' },
  { value: 49500000, label: '13:45' },
  { value: 50400000, label: '14:00' },
  { value: 51300000, label: '14:15' },
  { value: 52200000, label: '14:30' },
  { value: 53100000, label: '14:45' },
  { value: 54000000, label: '15:00' },
  { value: 54900000, label: '15:15' },
  { value: 55800000, label: '15:30' },
  { value: 56700000, label: '15:45' },
  { value: 57600000, label: '16:00' },
  { value: 58500000, label: '16:15' },
  { value: 59400000, label: '16:30' },
  { value: 60300000, label: '16:45' },
  { value: 61200000, label: '17:00' },
  { value: 62100000, label: '17:15' },
  { value: 63000000, label: '17:30' },
  { value: 63900000, label: '17:45' },
  { value: 64800000, label: '18:00' },
  { value: 65700000, label: '18:15' },
  { value: 66600000, label: '18:30' },
  { value: 67500000, label: '18:45' },
  { value: 68400000, label: '19:00' },
  { value: 69300000, label: '19:15' },
  { value: 70200000, label: '19:30' },
  { value: 71100000, label: '19:45' },
  { value: 72000000, label: '20:00' },
  { value: 72900000, label: '20:15' },
  { value: 73800000, label: '20:30' },
  { value: 74700000, label: '20:45' },
  { value: 75600000, label: '21:00' },
  { value: 76500000, label: '21:15' },
  { value: 77400000, label: '21:30' },
  { value: 78300000, label: '21:45' },
  { value: 79200000, label: '22:00' },
  { value: 80100000, label: '22:15' },
  { value: 81000000, label: '22:30' },
  { value: 81900000, label: '22:45' },
  { value: 82800000, label: '23:00' },
  { value: 83700000, label: '23:15' },
  { value: 84600000, label: '23:30' },
  { value: 85500000, label: '23:45' },
];
