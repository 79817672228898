import React, { useCallback } from 'react';

import { Field } from 'react-final-form';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { SOLVENTS_SELECT_OPTIONS } from './SolventsMap';
import { INJECTION_DRUGS, PILL_DRUGS } from './DrugsMap';

import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => {
  return {
    inputsWrapper: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      marginBottom: '20px',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    alignItems: {
      display: 'flex',
      alignItems: 'center',
    },
    error: {
      color: 'red',
    },
    mr20: {
      marginRight: '20px',
    },
    baseFormElement: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '130px',
      },
    },
    baseFormLargeElement: {
      [theme.breakpoints.up('md')]: {
        width: '230px',
      },
    },
  };
});

const sortFn = (drA, drB) => (drA.label > drB.label ? 1 : -1);

export default function AddDrugComponent({ name, what, handleDeleteInjectDose }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const getDrugs = useCallback(() => {
    if (what === 'W') {
      return Object.keys(PILL_DRUGS)
        .map((key) => ({ value: key, label: t(key) }))
        .sort(sortFn);
    }

    return Object.keys(INJECTION_DRUGS)
      .map((key) => ({ value: key, label: INJECTION_DRUGS[key] }))
      .sort(sortFn);
  }, [what]);
  return (
    <div className={classes.alignItems}>
      <div className={classes.inputsWrapper}>
        <Field name={`${name}.drug`}>
          {({ input, meta }) => (
            <div className={classes.mr20}>
              <TextField
                variant="standard"
                select
                label={t('medication1')}
                className={cn(classes.baseFormElement, classes.baseFormLargeElement)}
                {...input}
              >
                {getDrugs().map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {meta.error && meta.touched && <div className={classes.error}>{meta.error}</div>}
            </div>
          )}
        </Field>
        <Field name={`${name}.dose`}>
          {({ input, meta }) => (
            <div className={classes.mr20}>
              <TextField
                variant="standard"
                className={classes.baseFormElement}
                label={t('dose')}
                type="number"
                inputProps={{
                  min: 0.0,
                  step: 0.001,
                }}
                {...input}
              />
              {meta.error && meta.touched && <div className={classes.error}>{meta.error}</div>}
            </div>
          )}
        </Field>

        {what !== 'W' ? (
          <>
            <Field name={`${name}.volume`}>
              {({ input, meta }) => (
                <div className={classes.mr20}>
                  <TextField
                    variant="standard"
                    className={classes.baseFormElement}
                    label={t('volume')}
                    type="number"
                    placeholder="1.000"
                    inputProps={{
                      min: 0.001,
                      max: 10,
                      step: 0.001,
                    }}
                    {...input}
                  />
                  {meta.error && meta.touched && <div className={classes.error}>{meta.error}</div>}
                </div>
              )}
            </Field>

            <Field name={`${name}.solvent`}>
              {({ input, meta }) => (
                <div className={classes.mr20}>
                  <TextField
                    variant="standard"
                    select
                    label={t('solvent')}
                    className={classes.baseFormElement}
                    {...input}
                  >
                    {SOLVENTS_SELECT_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {meta.error && meta.touched && <div className={classes.error}>{meta.error}</div>}
                </div>
              )}
            </Field>
          </>
        ) : null}
      </div>
      <IconButton
        color="secondary"
        aria-label="delete"
        component="span"
        onClick={handleDeleteInjectDose}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
}
