import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import QueueIcon from '@mui/icons-material/Queue';
import { useTranslation } from 'react-i18next';

import { routes } from '../../routes';

const useStyles = makeStyles(() => ({
  link: {
    color: 'black',
    textDecoration: 'none',
  },
}));

export function MainListItems({
  handleNavClick,
}: {
  handleNavClick: React.MouseEventHandler<HTMLLIElement>;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Link to={routes.metrics} className={classes.link} onClick={handleNavClick}>
        <ListItemButton>
          <ListItemIcon>
            <SettingsAccessibilityIcon />
          </ListItemIcon>
          <ListItemText primary={t('anthropometry')} />
        </ListItemButton>
      </Link>
      <Link to={routes.course} className={classes.link} onClick={handleNavClick}>
        <ListItemButton>
          <ListItemIcon>
            <QueueIcon />
          </ListItemIcon>
          <ListItemText primary={t('farm_course')} />
        </ListItemButton>
      </Link>
      <Link to={routes.pharmacy} className={classes.link} onClick={handleNavClick}>
        <ListItemButton>
          <ListItemIcon>
            <MedicalServicesIcon />
          </ListItemIcon>
          <ListItemText primary={t('use_farm')} />
        </ListItemButton>
      </Link>
    </>
  );
}
