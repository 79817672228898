import React, { ReactElement, useCallback, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { styled, useTheme } from '@mui/material/styles';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import HelpOutlineSharp from '@mui/icons-material/HelpOutlineSharp';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { addAntros } from '../../api/addAntros';
import { deleteAntros } from '../../api/deleteAntros';
import { getFormattedDate } from '../../helpers/getFormattedDate';
import DeleteModal from '../Shared/DeleteModal';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import { parseJwt } from '../../helpers/parseJwt';
import { getCookie } from '../../utils/getCookie';
import { TOKEN_VARIABLE_NAME, MALE } from '../../consts';

import antro from '../../assets/antro.png';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '@mui/material/useMediaQuery';

import cn from 'classnames';

const useStyles = makeStyles((theme) => {
  return {
    openModalButton: {
      marginBottom: '10px',
    },
    modal: {
      padding: '20px',
      width: '100%',
      minWidth: 'unset',
      [theme.breakpoints.up('sm')]: {
        width: '500px',
      },
    },
    formControls: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    formControl: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        marginTop: 0,
      },
    },
    mb40: {
      marginBottom: '40px',
    },
    m10: {
      marginTop: '10px',
      marginBottom: '10px',
    },
    h4Custom: {
      fontSize: '18px',
      fontWeight: 600,
    },
    error: {
      color: 'red',
    },
    baseFormElement: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '460px',
      },
    },
  };
});

type TError = {
  general_height?: string;
  general_weight?: string;
  dt?: string;
  fold_chest?: string;
  fold_belly?: string;
  fold_hip_front?: string;
};

type THtmlTooltip = {
  title: ReactElement;
  children: ReactElement;
  className?: string;
};

//TODO нужно сделать чтобы реагировал на клик
const HtmlTooltip = styled(({ className, ...props }: THtmlTooltip) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    overflowY: 'auto',
    height: '300px',
    width: '600px',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export function SimpleDialog(props) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { onClose, selectedValue, open } = props;
  const classes = useStyles();
  const [error, setError] = useState<string>();
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const gender = parseJwt(getCookie(TOKEN_VARIABLE_NAME))?.sex;
  const theme = useTheme();
  const smSize = useMediaQuery(theme.breakpoints.up('sm'));

  const handleClose = useCallback(() => {
    onClose(selectedValue);
    setError(undefined);
    setIsDeleteModalOpened(false);
  }, [selectedValue, onClose]);
  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpened(true);
  };
  const handleDeleteAntros = useCallback(async () => {
    if (selectedValue) {
      await deleteAntros(selectedValue.ID);
      await queryClient.fetchQuery('antrosData');
    }
    handleClose();
  }, [selectedValue, handleClose, queryClient]);

  const onSubmit = async (data) => {
    const { dt, ...resData } = data;
    Object.keys(resData).forEach((key) => {
      resData[key] = Number(resData[key]);
    });
    const res = await addAntros({
      fold_chest: resData.fold_chest,
      general_height: resData.general_height,
      fold_belly: resData.fold_belly,
      fold_hip_front: resData.fold_hip_front,
      general_weight: resData.general_weight,
      ID: selectedValue ? selectedValue.ID : undefined,
      dt: dt ? `${dt}T00:00:00Z` : undefined,
    });

    if (!(res instanceof Error)) {
      await queryClient.fetchQuery('antrosData');
      handleClose();
    } else if (res.response && res.response.status === 401) {
      history.push('/login');
      return;
    } else {
      setError(getErrorMessage(res));
    }
  };

  const validate = (values) => {
    const errors: TError = {};
    if (!values.general_height || values.general_height <= 0) {
      errors.general_height = t('required_field');
    }

    if (!values.general_weight || values.general_weight <= 0) {
      errors.general_weight = t('required_field');
    }

    if (!values.dt) {
      errors.dt = t('required_field');
    }

    if (!values.fold_chest || values.fold_chest <= 0) {
      errors.fold_chest = t('required_field');
    }

    if (!values.fold_belly || values.fold_belly <= 0) {
      errors.fold_belly = t('required_field');
    }

    if (!values.fold_hip_front || values.fold_hip_front <= 0) {
      errors.fold_hip_front = t('required_field');
    }

    return errors;
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={!smSize}
      maxWidth="md"
    >
      <DialogTitle id="simple-dialog-title">{t('add_ant')}</DialogTitle>
      {error && <h2 className={classes.error}>{error}</h2>}
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={selectedValue || { dt: getFormattedDate() }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.modal}>
              <Field name="dt">
                {({ input, meta }) => (
                  <div>
                    <TextField
                      id="date"
                      variant="standard"
                      label={t('date')}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...input}
                    />
                    {meta.error && meta.touched && (
                      <div className={classes.error}>{meta.error}</div>
                    )}
                  </div>
                )}
              </Field>
              <div className={classes.mb40}>
                <div>
                  <div className={cn(classes.h4Custom, classes.m10)}>{t('common_data')}</div>
                  <Field name="general_height">
                    {({ input, meta }) => (
                      <div>
                        <TextField
                          className={classes.baseFormElement}
                          variant="standard"
                          label={t('height')}
                          inputProps={{
                            min: 100,
                            step: 0.1,
                          }}
                          {...input}
                          type="number"
                        />
                        {meta.error && meta.touched && (
                          <div className={classes.error}>{meta.error}</div>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="general_weight">
                    {({ input, meta }) => (
                      <div>
                        <TextField
                          className={classes.baseFormElement}
                          variant="standard"
                          label={t('weight')}
                          inputProps={{
                            min: 20,
                            step: 0.1,
                          }}
                          {...input}
                          type="number"
                        />
                        {meta.error && meta.touched && (
                          <div className={classes.error}>{meta.error}</div>
                        )}
                      </div>
                    )}
                  </Field>
                  <div>
                    <span>{t('folds')}</span>
                    {gender === MALE ? (
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <h4 color="inherit">{t('folds')}</h4>
                            {t('long_add_met_modal')}
                            <table>
                              <tr>
                                <td>
                                  {t('long_add_met_mod1')} - <br />
                                  {t('long_add_met_mod2')}
                                </td>
                                <td rowSpan={3}>
                                  <img src={antro} width="200" height="150" alt="antro" />
                                </td>
                              </tr>
                              <tr>
                                <td>{t('long_add_met_mod3')}</td>
                              </tr>
                              <tr>
                                <td>{t('long_add_met_mod4')}</td>
                              </tr>
                            </table>
                          </React.Fragment>
                        }
                      >
                        <IconButton>
                          <HelpOutlineSharp />
                        </IconButton>
                      </HtmlTooltip>
                    ) : (
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <h4 color="inherit">{t('folds')}</h4>
                            {t('long_add_met_modal')}
                            <table>
                              <tr>
                                <td>
                                  {t('long_add_met_mod1')} - <br />
                                  {t('long_add_met_mod2')}
                                </td>
                                <td rowSpan={3}>
                                  <img src={antro} width="200" height="150" alt="antro" />
                                </td>
                              </tr>
                              <tr>
                                <td>{t('long_add_met_mod3')}</td>
                              </tr>
                              <tr>
                                <td>{t('long_add_met_mod4')}</td>
                              </tr>
                            </table>
                          </React.Fragment>
                        }
                      >
                        <IconButton>
                          <HelpOutlineSharp />
                        </IconButton>
                      </HtmlTooltip>
                    )}
                  </div>
                  <Field name="fold_chest">
                    {({ input, meta }) => (
                      <div>
                        <TextField
                          className={classes.baseFormElement}
                          variant="standard"
                          label={t('chest')}
                          inputProps={{
                            min: 0.1,
                            step: 0.1,
                          }}
                          {...input}
                          type="number"
                        />
                        {meta.error && meta.touched && (
                          <div className={classes.error}>{meta.error}</div>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="fold_belly">
                    {({ input, meta }) => (
                      <div>
                        <TextField
                          className={classes.baseFormElement}
                          variant="standard"
                          label={t('abdomen')}
                          inputProps={{
                            min: 0.1,
                            step: 0.1,
                          }}
                          {...input}
                          type="number"
                        />
                        {meta.error && meta.touched && (
                          <div className={classes.error}>{meta.error}</div>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="fold_hip_front">
                    {({ input, meta }) => (
                      <div>
                        <TextField
                          className={classes.baseFormElement}
                          variant="standard"
                          label={t('hip_front')}
                          inputProps={{
                            min: 0.1,
                            step: 0.1,
                          }}
                          {...input}
                          type="number"
                        />
                        {meta.error && meta.touched && (
                          <div className={classes.error}>{meta.error}</div>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
              </div>
              <div className={classes.formControls}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                  className={classes.formControl}
                >
                  {t('save')}
                </Button>
                <Button variant="contained" onClick={handleClose} className={classes.formControl}>
                  {t('cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleOpenDeleteModal}
                  disabled={!selectedValue}
                  className={classes.formControl}
                >
                  {t('delete')}
                </Button>
              </div>
            </div>
          </form>
        )}
      />
      <DeleteModal
        text={t('sure_del_met')}
        customOpen={isDeleteModalOpened}
        onDeleteButtonPress={handleDeleteAntros}
        handleClose={() => setIsDeleteModalOpened(false)}
      />
    </Dialog>
  );
}

export default function AddPharmacyModal() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const { t } = useTranslation();

  return (
    <div className={classes.openModalButton}>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        {t('add')}
      </Button>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
    </div>
  );
}
