import React, { useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';

import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { addFeedback, TFeedback } from '../../api/addFeedback';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import { parseJwt } from '../../helpers/parseJwt';
import { getCookie } from '../../utils/getCookie';
import { TOKEN_VARIABLE_NAME } from '../../consts';
import { availableLanguages } from '../Language/i18n';
import { LANGUAGE_MAP } from '../Language/LanguageMap';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  openModalButton: {
    marginBottom: '10px',
  },
  modal: {
    padding: '20px',
    minWidth: '400px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px',
  },
  mb40: {
    marginBottom: '40px',
  },
  m10: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  h4Custom: {
    fontSize: '18px',
    fontWeight: 600,
  },
  error: {
    color: 'red',
  },
  MuiFormControl2: {
    width: '400px',
  },
  MuiFormControl3: {
    width: '400px',
    height: 'unset',
  },
});

type TError = {
  email?: string;
  name?: string;
  feedback?: string;
};

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export function SimpleDialog(props) {
  const { t } = useTranslation();
  const { onClose, open } = props;
  const classes = useStyles();
  const [error, setError] = useState<string>();
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    onClose();
    setError(undefined);
    setShowSuccessModal(false);
  }, [onClose]);

  const onSubmit = async (data: TFeedback) => {
    const res = await addFeedback({
      ...data,
      location: window.location.href,
    });

    if (!(res instanceof Error)) {
      setShowSuccessModal(true);
    } else {
      setError(getErrorMessage(res));
    }
  };

  const validate = (values) => {
    const errors: TError = {};
    if (!values.email) {
      errors.email = t('required_field');
    }

    if (!values.name) {
      errors.name = t('required_field');
    }

    if (!values.feedback) {
      errors.feedback = t('required_field');
    }

    return errors;
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {t('feedback')}
      </BootstrapDialogTitle>

      {error && <h2 className={classes.error}>{error}</h2>}
      {showSuccessModal && (
        <div className={classes.center}>
          <CheckCircleOutline color="success" sx={{ fontSize: 80 }} />
          <h3>{t('feedback_thanks')}</h3>
        </div>
      )}
      {!showSuccessModal && (
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={{ email: parseJwt(getCookie(TOKEN_VARIABLE_NAME))?.email }}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.modal}>
                <Field name="email">
                  {({ input, meta }) => (
                    <div>
                      <TextField
                        variant="standard"
                        label={t('enter_email')}
                        className={classes.MuiFormControl2}
                        type="text"
                        inputProps={{ minLength: 3, maxLength: 30 }}
                        {...input}
                      />
                      {meta.error && meta.touched && (
                        <div className={classes.error}>{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>

                <Field name="name">
                  {({ input, meta }) => (
                    <div>
                      <TextField
                        variant="standard"
                        label={t('enter_name')}
                        className={classes.MuiFormControl2}
                        type="text"
                        inputProps={{ minLength: 1, maxLength: 30 }}
                        {...input}
                      />
                      {meta.error && meta.touched && (
                        <div className={classes.error}>{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>

                <Field name="feedback">
                  {({ input, meta }) => (
                    <div>
                      <TextField
                        variant="standard"
                        label={t('txt_mess')}
                        className={classes.MuiFormControl3}
                        type="text"
                        multiline
                        {...input}
                      />
                      {meta.error && meta.touched && (
                        <div className={classes.error}>{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <br />
                <div className={classes.row}>
                  <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                    {t('send')}
                  </Button>
                  <Button variant="contained" onClick={handleClose}>
                    {t('cancel')}
                  </Button>
                </div>
              </div>
            </form>
          )}
        />
      )}
    </Dialog>
  );
}

export default function Home() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', padding: '20px' }}>
      <Button
        variant="contained"
        color="primary"
        style={{ marginLeft: 'auto' }}
        onClick={handleClickOpen}
      >
        {t('questions')}
      </Button>
      <div>&nbsp;&nbsp;</div>
      <select defaultValue={'en'} onChange={(e) => i18n.changeLanguage(e.target.value)}>
        {availableLanguages.map((language) => (
          <option key={language} value={language}>
            {LANGUAGE_MAP[language]}
          </option>
        ))}
      </select>
      <SimpleDialog open={open} onClose={handleClose} />
    </div>
  );
}
