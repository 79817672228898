import axios, { type AxiosError } from 'axios';
import { getCookie } from '../utils/getCookie';
import { TOKEN_VARIABLE_NAME } from '../consts';
import { BASE_URL } from './consts';

export type TCryptoPaymentAll = {
  dt: string;
  amount: string;
  currency: string;
  invoiceid: string;
  status: string;
  payurl: string;
  dtpaym: string;
  Statusinvoice: string;
};
export type TCryptoPaymentAlls = TCryptoPaymentAll[];

export const getCryptoPaymentAll = async (): Promise<TCryptoPaymentAlls | AxiosError> =>
  axios
    .get(`${BASE_URL}/crypto-payment-all`, {
      headers: {
        Authorization: `Bearer ${getCookie(TOKEN_VARIABLE_NAME)}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => error);
