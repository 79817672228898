import React, { useCallback, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { getFormattedDate, getFormattedDate2 } from '../../helpers/getFormattedDate';
import { routes } from '../../routes';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import { useTranslation } from 'react-i18next';
import { getUpdateHistorys, type TUpdateHistory } from '../../api/getUpdatehistory';

const useStyles = makeStyles(() => ({
  block: {
    padding: '20px',
  },
  error: {
    color: 'red',
  },
}));

export default function Index() {
  const classes = useStyles();
  const history = useHistory();

  const { isLoading, data } = useQuery('updatehistorysData', getUpdateHistorys, {
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const { t } = useTranslation();

  if (data instanceof Error) {
    if (data.response?.status === 401) {
      history.push(routes.login);
      return null;
    }
    return <h3 className={classes.error}>{getErrorMessage(data)}</h3>;
  }
  //TODO нужно сделать отоброжение ENG версии изменнений
  return (
    <>
      <div>
        <h3>{t('udate_history')}</h3>

        {data &&
          data.map((row, index) => (
            <Card key={index} sx={{ mt: 2 }}>
              <CardContent>
                <div>{`${getFormattedDate2(row.created_at)}`}</div>
                <div>
                  {row.description_ru.split('\r\n').map((i, key) => {
                    return <div key={key}>{i}</div>;
                  })}
                </div>
              </CardContent>
            </Card>
          ))}
        {isLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </div>
    </>
  );
}
