export const SOLVENTS = Object.freeze({
  W: 'Вода',
  O: 'Масло',
  L: 'Олеат',
});

export const SOLVENTS_SELECT_OPTIONS = [
  {
    value: 'W',
    label: 'Вода',
  },
  {
    value: 'O',
    label: 'Масло',
  },
  {
    value: 'L',
    label: 'Олеат',
  },
];
