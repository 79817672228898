import axios, { type AxiosError } from 'axios';
import { getCookie } from '../utils/getCookie';
import { TOKEN_VARIABLE_NAME } from '../consts';
import { BASE_URL } from './consts';
import { getFormattedDate } from '../helpers/getFormattedDate';
import { type TInjectionGraph } from './getInjectionGraphs';

// format of dates = 2021-11-10
export const getInjectionReportGraphs = async ([sdt, fdt]: string[]): Promise<
  TInjectionGraph | AxiosError
> =>
  axios
    .get(`${BASE_URL}/injections/report/${getFormattedDate(sdt)}/${getFormattedDate(fdt)}/`, {
      headers: {
        Authorization: `Bearer ${getCookie(TOKEN_VARIABLE_NAME)}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => error);
