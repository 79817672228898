import React, { useCallback, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import { getFormattedDate } from '../../helpers/getFormattedDate';
import DeleteModal from '../Shared/DeleteModal';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import { COURSE_TARGET, COURSE_TYPE } from '../Pharmacy/InjectionOptions';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import _without from 'lodash/without';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { addCourse } from '../../api/addCourse';
import { deleteCourse } from '../../api/deleteCourse';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

const useStyles = makeStyles((theme) => {
  return {
    openModalButton: {
      marginBottom: '10px',
    },
    modal: {
      padding: '20px',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '500px',
      },
    },
    formControls: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    formControl: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        marginTop: 0,
      },
    },
    mb40: {
      marginBottom: '40px',
    },
    m10: {
      marginTop: '10px',
      marginBottom: '10px',
    },
    h4Custom: {
      fontSize: '18px',
      fontWeight: 600,
    },
    redBackground: {
      backgroundColor: '#C00',
      padding: 10,
    },
    whiteBackground: {
      backgroundColor: '#FFF',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
      backgroundColor: '#FFF',
    },
    MuiFormControl3: {
      height: 'unset',
    },
    error: {
      color: 'red',
    },
    baseFormElement: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '460px',
      },
    },
  };
});

type TError = {
  course_start?: string;
  course_end?: string;
  type?: string;
  target?: string;
  notes?: string;
};

export function SimpleDialog(props) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { onClose, selectedValue, open } = props;
  const classes = useStyles();
  const [error, setError] = useState<string>();
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const theme = useTheme();
  const smSize = useMediaQuery(theme.breakpoints.up('sm'));

  //const initialSelected = ["April Tucker", "Ralph Hubbard"];
  const initialSelected = [];
  //нужно сделать предзаполнение при редактировании
  //const initialSelected = selectedValue.target.replace('{','').replace('}','').split(',');
  const [personName, setPersonName] = React.useState<string[]>(initialSelected);

  const handleChange = (event) => {
    setPersonName(event.target.value as string[]);
  };

  const handleDelete = (e: React.MouseEvent, value: string) => {
    e.preventDefault();
    console.log('clicked delete');
    setPersonName((current) => _without(current, value));
  };

  const handleClose = useCallback(() => {
    onClose(selectedValue);
    setError(undefined);
    setIsDeleteModalOpened(false);
  }, [selectedValue, onClose]);

  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpened(true);
  };
  const handleDeleteCourse = useCallback(async () => {
    if (selectedValue) {
      await deleteCourse(selectedValue.id);
      await queryClient.fetchQuery('courseData');
    }
    handleClose();
  }, [selectedValue, handleClose, queryClient]);

  const onSubmit = async (data) => {
    const res = await addCourse({
      id: data.id,
      course_start: `${data.course_start}T00:00:00Z`,
      course_end: `${data.course_end}T00:00:00Z`,
      type: data.type,
      target: personName.join(),
      notes: data.notes,
    });

    if (!(res instanceof Error)) {
      await queryClient.fetchQuery('courseData');
      handleClose();
    } else if (res.response && res.response.status === 401) {
      history.push('/login');
      return;
    } else {
      setError(getErrorMessage(res));
    }
  };

  const validate = (values) => {
    const errors: TError = {};

    if (!values.course_start) {
      errors.course_start = t('required_field');
    }

    if (!values.course_end) {
      errors.course_end = t('required_field');
    }

    if (values.course_start && values.course_end && values.course_end < values.course_start) {
      errors.course_end = t('date_more');
    }

    if (!values.type) {
      errors.type = t('required_field');
    }

    if (!values.notes) {
      errors.notes = t('required_field');
    }

    if (personName.length == 0) {
      errors.target = t('required_field');
    }

    return errors;
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={!smSize}
      maxWidth="md"
    >
      <DialogTitle id="simple-dialog-title">{t('add_course')}</DialogTitle>
      {error && <h2 className={cn(classes.error, classes.baseFormElement)}>{error}</h2>}
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={
          selectedValue || {
            course_start: getFormattedDate(),
            course_end: getFormattedDate(),
          }
        }
        //initialValues={getInitialValues() }
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.modal}>
              <Field name="course_start">
                {({ input, meta }) => (
                  <div>
                    <TextField
                      id="date"
                      variant="standard"
                      label={t('date_start')}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...input}
                    />
                    {meta.error && meta.touched && (
                      <div className={cn(classes.error, classes.baseFormElement)}>{meta.error}</div>
                    )}
                  </div>
                )}
              </Field>
              <br />
              <Field name="course_end">
                {({ input, meta }) => (
                  <div>
                    <TextField
                      id="date"
                      variant="standard"
                      label={t('date_end')}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...input}
                    />
                    {meta.error && meta.touched && (
                      <div className={cn(classes.error, classes.baseFormElement)}>{meta.error}</div>
                    )}
                  </div>
                )}
              </Field>
              <br />
              <Field name="type">
                {({ input, meta }) => (
                  <div>
                    <TextField
                      id="standard-select-currency"
                      select
                      multiple
                      label={t('prep_period')}
                      variant="standard"
                      className={cn(classes.MuiFormControl3, classes.baseFormElement)}
                      {...input}
                    >
                      {Object.keys(COURSE_TYPE).map((key) => (
                        <MenuItem key={key} value={key}>
                          {COURSE_TYPE[key]}
                        </MenuItem>
                      ))}
                    </TextField>
                    {meta.error && meta.touched && (
                      <div className={cn(classes.error, classes.baseFormElement)}>{meta.error}</div>
                    )}
                  </div>
                )}
              </Field>

              <br />

              <Field name="target">
                {({ meta }) => (
                  <div>
                    <FormControl className={classes.baseFormElement}>
                      <InputLabel id="demo-mutiple-chip-label">{t('course_goal')}</InputLabel>
                      <Select
                        labelId="demo-mutiple-chip-checkbox-label"
                        id="demo-mutiple-chip-checkbox"
                        label={t('course_goal')}
                        multiple
                        value={personName}
                        onChange={handleChange}
                        onOpen={() => console.log('select opened')}
                        //input={<Input />}
                        // MenuProps={MenuProps}
                        IconComponent={KeyboardArrowDownIcon}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {(selected as string[]).map((value) => (
                              <Chip
                                key={value}
                                label={COURSE_TARGET[value]}
                                clickable
                                deleteIcon={
                                  <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                                }
                                className={classes.chip}
                                onDelete={(e) => handleDelete(e, value)}
                                onClick={() => console.log('clicked chip')}
                              />
                            ))}
                          </div>
                        )}
                      >
                        {Object.keys(COURSE_TARGET).map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={personName.includes(name)} />
                            <ListItemText primary={COURSE_TARGET[name]} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {meta.error && meta.touched && (
                      <div className={cn(classes.error, classes.baseFormElement)}>{meta.error}</div>
                    )}
                  </div>
                )}
              </Field>
              <br />
              <Field name="notes">
                {({ input, meta }) => (
                  <div>
                    <TextField
                      variant="standard"
                      label={t('notes')}
                      className={cn(classes.MuiFormControl3, classes.baseFormElement)}
                      type="text"
                      multiline
                      {...input}
                    />
                    {meta.error && meta.touched && (
                      <div className={cn(classes.error, classes.baseFormElement)}>{meta.error}</div>
                    )}
                  </div>
                )}
              </Field>
              <br />
              <br />
              <div className={classes.formControls}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                  className={classes.formControl}
                >
                  {t('save')}
                </Button>
                <Button variant="contained" onClick={handleClose} className={classes.formControl}>
                  {t('cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleOpenDeleteModal}
                  disabled={!selectedValue}
                  className={classes.formControl}
                >
                  {t('delete')}
                </Button>
              </div>
            </div>
          </form>
        )}
      />
      <DeleteModal
        text={t('sure_del_cours')}
        customOpen={isDeleteModalOpened}
        onDeleteButtonPress={handleDeleteCourse}
        handleClose={() => setIsDeleteModalOpened(false)}
      />
    </Dialog>
  );
}

export default function AddPharmacyModal() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  const { t } = useTranslation();

  return (
    <div className={classes.openModalButton}>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        {t('add')}
      </Button>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
    </div>
  );
}
