import React, { useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

export function SimpleDialog(props) {
  const { onClose, open } = props;

  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  // keyPrefix - вроде от этой переменной надо оттолкнуться
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <h1>Пользовательское Соглашение</h1>
      <ul>
        <li>
          Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между ООО
          &quot;Морч&quot; (далее ChartDrug или Администрация) с одной стороны и пользователем сайта
          с другой.
        </li>
        <li>Сайт ChartDrug не является средством массовой информации.</li>
        <li>Используя сайт, Вы соглашаетесь с условиями данного соглашения.</li>
        <li>Если Вы не согласны с условиями данного соглашения, не используйте сайт ChartDrug!</li>
      </ul>

      <h1>Предмет соглашения</h1>
      <p>
        Администрация предоставляет пользователю право на размещение на сайте следующей информации:
      </p>
      <ul>
        <li>Текстовой информации</li>
      </ul>

      <h1>Права и обязанности сторон</h1>
      <h2>Пользователь имеет право:</h2>
      <ul>
        <li>осуществлять поиск информации на сайте</li>
        <li>получать информацию на сайте</li>
        <li>требовать от администрации скрытия любой информации о пользователе</li>
        <li>требовать от администрации скрытия любой информации переданной пользователем сайту</li>
        <li>использовать информацию сайта в личных некоммерческих целях</li>
      </ul>

      <h2>Администрация имеет право:</h2>
      <ul>
        <li>по своему усмотрению и необходимости создавать, изменять, отменять правила</li>
        <li>создавать, изменять, удалять информацию</li>
      </ul>

      <h2>Пользователь обязуется:</h2>
      <ul>
        <li>обеспечивать сохранность личных данных от доступа третьих лиц</li>
        <li>не нарушать работоспособность сайта</li>
        <li>
          не создавать несколько учётных записей на Сайте, если фактически они принадлежат одному и
          тому же лицу
        </li>
        <li>
          не передавать в пользование свою учетную запись и/или логин и пароль своей учетной записи
          третьим лицам
        </li>
        <li>
          не регистрировать учетную запись от имени или вместо другого лица за исключением случаев,
          предусмотренных законодательством РФ
        </li>
        <li>
          не использовать скрипты (программы) для автоматизированного сбора информации и/или
          взаимодействия с Сайтом и его Сервисами
        </li>
      </ul>

      <h2>Администрация обязуется:</h2>
      <ul>
        <li>
          поддерживать работоспособность сайта за исключением случаев, когда это невозможно по
          независящим от Администрации причинам.
        </li>
      </ul>

      <h2>Ответственность сторон</h2>
      <ul>
        <li>пользователь лично несет полную ответственность за распространяемую им информацию</li>
        <li>
          администрация не несёт ответственность за несовпадение ожидаемых Пользователем и реально
          полученных услуг
        </li>
        <li>
          администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами
        </li>
        <li>
          в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение,
          стихийное бедствие и т. д.) Администрация не гарантирует сохранность информации,
          размещённой Пользователем, а также бесперебойную работу информационного ресурса
        </li>
      </ul>

      <h1>Условия действия Соглашения</h1>
      <ul>
        <li>Данное Соглашение вступает в силу при регистрации на сайте.</li>
        <li>Соглашение перестает действовать при появлении его новой версии.</li>
        <li>
          Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение
          по своему усмотрению.
        </li>
        <li>
          При изменении соглашения, в некоторых случаях, администрация может оповестить
          пользователей удобным для нее способом.
        </li>
      </ul>
      <br />
      <Button variant="contained" onClick={handleClose}>
        {t('back')}
      </Button>
    </Dialog>
  );
}

export default function Home() {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <a href="#" onClick={handleClickOpen}>
        "{t('terms_of_use')}"
      </a>
      <SimpleDialog open={open} onClose={handleClose} />
    </>
  );
}
