import axios, { type AxiosResponse, type AxiosError } from 'axios';
import { BASE_URL } from './consts';

export type TUserLoginData = {
  username: string;
  password: string;
};

export const login = async (userData: TUserLoginData): Promise<string | AxiosError> =>
  axios
    .post(`${BASE_URL}/login`, userData)
    .then((response: AxiosResponse): string => {
      const { token } = response.data;
      return token;
    })
    .catch((error: AxiosError): AxiosError => error);
