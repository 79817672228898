import axios, { type AxiosError } from 'axios';
import { getCookie } from '../utils/getCookie';
import { TOKEN_VARIABLE_NAME } from '../consts';
import { BASE_URL } from './consts';

type TPointValues = {
  CCT: number;
  drug: string;
};

type TPoint = {
  dt: number;
  pointValues: TPointValues[];
};

export type TInjectionGraph = {
  drugs: string[];
  point: TPoint[];
};

export const getInjectionGraphs = async (id: string): Promise<TInjectionGraph | AxiosError> =>
  axios
    .get(`${BASE_URL}/injections/course/report/${id}`, {
      headers: {
        Authorization: `Bearer ${getCookie(TOKEN_VARIABLE_NAME)}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => error);
