import axios, { type AxiosError } from 'axios';
import { getCookie } from '../utils/getCookie';
import { TOKEN_VARIABLE_NAME } from '../consts';
import { BASE_URL } from './consts';

export type TUpdateHistory = {
  id: string;
  created_at: string;
  description_ru: string;
  description_en: string;
};

type TUpdateHistorys = TUpdateHistory[];

export const getUpdateHistorys = async (): Promise<TUpdateHistorys | AxiosError> =>
  axios
    .get(`${BASE_URL}/history_update`, {
      headers: {
        Authorization: `Bearer ${getCookie(TOKEN_VARIABLE_NAME)}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => error);
