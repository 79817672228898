export const routes = {
  root: '/',
  metrics: '/metrics',
  drugs: '/drugs',
  pharmacy: '/pharmacy',
  course: '/course',
  users: '/users',
  feedbacks: '/feedbacks',
  profile: '/profile',
  login: '/login',
  register: '/register',
  restorePassword: '/restorePassword',
  userManual: '/userManual',
  manual: '/manual',
  contacts: '/contacts',
  subscription: '/subscription',
  updatehistory: '/updatehistory',
  cryptocloudsuccessfulpayment: '/CryptocloudSuccessfulPayment',
  cryptocloudfailedpayment: '/CryptocloudFailedPayment',
};
