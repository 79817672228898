export const INJECTIONS_OPTIONS = Object.freeze({
  W: 'Таблетка',
  1: 'Инъекция внутримышечная',
  2: 'Инъекция подкожная',
});

export const INJECTIONS_SELECT_OPTIONS = [
  {
    value: 'W',
    label: 'Таблетка',
  },
  {
    value: '1',
    label: 'Инъекция внутримышечная',
  },
  {
    value: '2',
    label: 'Инъекция подкожная',
  },
];

export const COURSE_TYPE = Object.freeze({
  P: 'Подготовительный',
  C: 'Соревновательный',
  I: 'Переходный',
});

export const COURSE_TARGET = Object.freeze({
  '00000001-0001-0000-0000-ff00ff00ff00': 'Увеличение мышечной массы',
  '00000002-0001-0000-0000-ff00ff00ff00': 'Уменьшение мышечной массы',
  '00000003-0001-0000-0000-ff00ff00ff00': 'Увеличение подкожного жира',
  '00000004-0001-0000-0000-ff00ff00ff00': 'Уменьшение подкожного жира',
  '00000005-0001-0000-0000-ff00ff00ff00': 'Увеличение силы целевых мышечных групп',
  '00000006-0001-0000-0000-ff00ff00ff00': 'Увеличение выносливости целевых мышечных групп',
  '00000007-0001-0000-0000-ff00ff00ff00': 'Увеличение процента рекрутируемых мышечных волокон',
});
